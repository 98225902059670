import { styled } from "styled-components";
import Assets from "../assets";
import { AssetLike } from "./SelectAsset";

const Container = styled.div`
  display: grid;
  grid-template-columns: auto 1fr auto;
  grid-template-rows: auto;
  align-items: center;
  height: 50px;
  padding: 10px;
  width: calc(100% - 20px);
  border: 2px solid #404040;
  border-radius: 15px;
`;

const GasButton = styled.button`
  display: flex;
  align-items: center;
  gap: 10px;
  grid-row: 1;
  grid-column: 1;
  background: none;
  border: none;
  outline: none;
  border-radius: 10px;
  padding: 5px;
  height: 100%;
  cursor: pointer;
  &:hover {
    background-color: #fff1;
  }
`;

const GasLogo = styled.img`
  width: 35px;
  height: auto;
`;

const GasSymbol = styled.p`
  font-family: "Inter";
  font-weight: 500;
  font-size: 20px;
  color: #fff;
`;

const GasContainer = styled.div`
  grid-column: 3;
  grid-row: 1;
  display: flex;
  align-items: center;
`;

const Amount = styled(GasSymbol)`
  font-weight: 400;
  font-size: 20px;
  color: #404040;
`;

const ArrowDown = styled.img``;

export type GasSelectProps = {
  amount?: number | string;
  selected?: AssetLike;
  onSelectClick?: () => void;
};

function GasSelect({
  amount,
  selected,
  onSelectClick,
}: GasSelectProps): JSX.Element {
  return (
    <Container>
      <GasButton onClick={onSelectClick}>
        <GasLogo
          src={selected?.image ?? Assets.Disabled}
          alt={selected?.name ?? "select"}
        />
        <GasSymbol>
          {selected?.symbol?.toUpperCase() ?? "Select gas token"}
        </GasSymbol>
        <ArrowDown src={Assets.buttons.ArrowDown} alt="arrow-down" />
      </GasButton>
      <GasContainer>
        <Amount>Fee: {amount ?? "N/A"}</Amount>
      </GasContainer>
    </Container>
  );
}

export default GasSelect;
