import { useCallback } from "react";
import { RequestHandler } from "./useWalletConnect";
import { Request, TransactionRequest } from "../request";
import { z } from "zod";
import { NETWORKS, Network } from "../networks";
import { BigNumber } from "@ethersproject/bignumber";

export type TransactionArgs = {
  request: z.infer<typeof TransactionRequest>;
  network: Network;
  resolve: (hash: string) => void;
  reject: (reason?: any) => void;
};

export type UseRequestHandlerProps = {
  setTransaction: (transaction: TransactionArgs | undefined) => void;
};

export default function useRequestHandler({
  setTransaction,
}: UseRequestHandlerProps) {
  return useCallback<RequestHandler>(
    ({ request, chainId }) => {
      console.log(request, chainId);
      try {
        var parsedRequest = Request.parse(request);
      } catch (error) {
        let { message } = error as z.ZodError;
        return {
          error: {
            code: -32601,
            message: `Method "${request.method}" not found or not supported: ${message}.`,
          },
        };
      }
      console.log({ parsedRequest });
      switch (parsedRequest.method) {
        case "eth_sendTransaction":
          let [transactionRequest] = parsedRequest.params;
          let network = NETWORKS.find((network) =>
            BigNumber.from(network.chainId).eq(chainId),
          );
          return new Promise<string>((resolve, reject) => {
            if (network === undefined) {
              reject(`ChainId ${chainId} not supported.`);
            } else {
              setTransaction({
                request: transactionRequest,
                network,
                resolve,
                reject,
              });
            }
          })
            .then((result) => ({ result }))
            .catch((message) => ({ error: { code: -32603, message } }))
            .finally(() => setTransaction(undefined));
        case "wallet_switchEthereumChain":
          return {
            result: null,
          };
        default:
          return {
            error: {
              code: -32601,
              message: `Method "${request.method}" not found or not supported.`,
            },
          };
      }
    },
    [setTransaction],
  );
}
