import type { Asset } from "@uroboros-labs/wallet-sdk";
import Fuse from "fuse.js";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { styled } from "styled-components";
import { Search } from "./Dashboard";
import { BackButton } from "./SelectNetwork";
import ga from "react-ga";
import Assets from "../assets";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 25px;
  width: 100%;
`;

const AssetsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  max-height: 500px;
  overflow-y: scroll;
`;

// eslint-disable-next-line @typescript-eslint/no-redeclare
const Asset = styled.button`
  display: grid;
  grid-template-columns: 40px 1fr 25px 100px;
  grid-template-rows: 1fr 1fr;
  align-items: center;
  column-gap: 15px;
  outline: none;
  border: none;
  background: none;
  padding: 10px;
  border-radius: 10px;
  cursor: pointer;
  &:hover {
    background-color: #fff1;
  }
`;

const AssetImage = styled.img`
  grid-column: 1;
  grid-row: 1 / -1;
  width: 100%;
`;

const AssetSymbol = styled.p`
  text-align: start;
  grid-column: 2;
  grid-row: 1;
  font-family: "Poppins";
  font-size: 17px;
  font-weight: 600;
  color: #fff;
`;

const AssetName = styled(AssetSymbol)`
  grid-row: 2;
  font-weight: 400;
  font-size: 15px;
  color: #ccc;
`;

const Warning = styled.img`
  grid-column: 3;
  grid-row: 1/-1;
  justify-self: center;
`;

const AssetPrice = styled(AssetSymbol)`
  grid-column: 4;
  grid-row: 1 / -1;
`;

const SearchAsset = styled(Search)`
  width: 100%;
`;

const Title = styled.h1`
  font-family: "Poppins";
  font-size: 23px;
  font-weight: 500;
  color: #fff;
  text-align: center;
`;

export type AssetLike = {
  name: string;
  symbol: string;
  image?: string;
  price?: number;
  value?: number;
  amount?: number;
};

export type SelectAssetProps = {
  assets: AssetLike[];
  onSelect?: (asset: AssetLike, index: number) => void;
  onClose?: () => void;
  isWarning?: (asset: AssetLike, index: number) => boolean;
};

const THRESHOLD: number = 0.8;
const PAGE_SIZE: number = 25;

function SelectAsset({
  assets,
  onSelect,
  onClose,
  isWarning,
}: SelectAssetProps): JSX.Element {
  useEffect(() => {
    ga.modalview("selectAsset");
    return () =>
      ga.event({
        category: "Swap/Send",
        action: "Closed selectAsset",
      });
  }, []);
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState("");
  const onScroll = useCallback(
    (e: React.UIEvent<HTMLDivElement>) => {
      if (search !== "") return;
      let target = e.currentTarget;
      let scrollProgres =
        target.scrollTop / (target.scrollHeight - target.clientHeight);
      if (scrollProgres >= THRESHOLD) {
        setPage((page) => page + 1);
      }
    },
    [search],
  );
  useEffect(() => {
    ga.event({
      category: "Swap/Send",
      action: "Scrolled to bottom in selectAsset",
      value: page,
    });
  }, [page]);
  const fuse = useMemo(
    () => new Fuse(assets, { keys: ["name", "symbol"] }),
    [assets],
  );
  const fusedAssets = useMemo(
    () =>
      fuse.search(search, { limit: PAGE_SIZE }).map((result) => result.item),
    [fuse, search],
  );
  useEffect(() => console.log({ search }), [search]);
  return (
    <Container>
      <BackButton onClick={onClose} />
      <Title>Select asset</Title>
      <SearchAsset
        placeholder="Search asset"
        value={search}
        onFocus={() => {
          ga.event({
            category: "Swap/Send",
            action: "Entered search field",
          });
        }}
        onChange={(e) => {
          setSearch(e.target.value);
        }}
      />
      <AssetsContainer onScroll={onScroll}>
        {(search !== "" ? fusedAssets : assets.slice(0, page * PAGE_SIZE)).map(
          (asset, index) => (
            <Asset
              key={index}
              onClick={() => {
                onSelect?.(asset, index);
                ga.event({
                  category: "Swap/Send",
                  action: "Selected asset",
                  label: asset.name,
                });
              }}
            >
              <AssetImage src={asset.image} alt={asset.name} loading="lazy" />
              <AssetSymbol>{asset.symbol.toUpperCase()}</AssetSymbol>
              <AssetName>{asset.name}</AssetName>
              {isWarning?.(asset, index) && (
                <Warning
                  src={Assets.Warning}
                  title="Warning! Higher cost because of swap."
                />
              )}
              {asset.value && (
                <AssetPrice>${asset.value.toFixed(5)}</AssetPrice>
              )}
            </Asset>
          ),
        )}
      </AssetsContainer>
    </Container>
  );
}

export default SelectAsset;
