import { styled } from "styled-components";
import assets from "../assets";
import { Fragment, useEffect } from "react";
import { Network } from "../networks";
import { formatHash } from "../pages/Transactions";
import ga from "react-ga";

const Container = styled.div`
  display: grid;
  grid-template-columns: 25px 1fr 25px;
  column-gap: 15px;
  row-gap: 15px;
  width: 300px;
  padding: 30px 20px;
  background-color: #151515;
  border: 2px solid #373737;
  border-radius: 5px;
`;

const Chain = styled.img`
  grid-column: 1;
  width: 25px;
  height: 25px;
`;

const InfoContainer = styled.div`
  grid-column: 2;
  display: flex;
  flex-direction: column;
`;

const InfoTitle = styled.h1`
  font-family: "Poppins";
  font-size: 18px;
  font-weight: 500;
  color: #fff;
`;

const InfoDescription = styled.p`
  font-family: "Poppins";
  font-size: 16px;
  font-weight: 400;
  color: #616265;
`;

const Loading = styled.img`
  grid-column: 3;
  animation: rotation 1s linear infinite;
  -webkit-animation: rotation 1s linear infinite;
`;

// type Entry = {
//   img: string;
//   title: string;
//   description: string;
// };

// const entries: Entry[] = [
//   {
//     img: assets.Optimism,
//     title: "0x6d6228...534d9b",
//     description: "1/3 Relay: enqueued",
//   },
//   {
//     img: assets.Optimism,
//     title: "0x6d6228...534d9b",
//     description: "txHash: 0x8e4d29...736f9c<br />3/3 LayerZero: pending",
//   },
// ];

export type PendingModalProps = {
  tx: Iterable<
    [
      string,
      {
        network: Network;
        description: string;
      },
    ]
  >;
};

function PendingModal({ tx }: PendingModalProps): JSX.Element {
  useEffect(() => {
    ga.modalview("pending");
    return () =>
      ga.event({
        category: "Account",
        action: "Closed pending",
      });
  }, []);

  return (
    <Container>
      {Array.from(tx).map(([hash, { network, description }], key) => (
        <Fragment key={key}>
          <Chain src={network.icon} alt="optimism" />
          <InfoContainer
            onClick={() => {
              ga.event({
                category: "Account",
                action: "Clicked on pending tx",
              });
            }}
          >
            <InfoTitle>{formatHash(hash)}</InfoTitle>
            <InfoDescription>{description}</InfoDescription>
          </InfoContainer>
          <Loading src={assets.Loading} alt="loading" />
        </Fragment>
      ))}
    </Container>
  );
}

export default PendingModal;
