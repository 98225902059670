import { SignatureLike, splitSignature } from "@ethersproject/bytes";
import { z } from "zod";

let id = 0;

export async function unlock(
  code: string,
  signature: SignatureLike,
  init?: RequestInit,
): Promise<void> {
  let response = await fetch("https://api.uroboros.co/unlocker", {
    ...init,
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      jsonrpc: "2.0",
      id: id++,
      method: "unlock",
      params: [code, splitSignature(signature)],
    }),
  }).then((res) => res.json());
  console.log({ response });
  if ("error" in response) {
    throw new Error(response.error.message);
  }
}

export async function unlocked(
  address: string,
  init?: RequestInit,
): Promise<boolean> {
  let response = await fetch("https://api.uroboros.co/unlocker", {
    ...init,
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      jsonrpc: "2.0",
      id: id++,
      method: "unlocked",
      params: [address],
    }),
  }).then((res) => res.json());
  console.log({ response });
  if ("error" in response) {
    throw new Error(response.error.message);
  }
  return z.boolean().parse(response.result);
}
