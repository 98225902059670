import { Web3ReactHooks, useWeb3React } from "@web3-react/core";
import { Connector } from "@web3-react/types";
import { useNavigate } from "react-router-dom";
import { styled } from "styled-components";
import assets from "../assets";
import { CONNECTOR_INFO } from "../connections";
import ga from "react-ga";

const Main = styled.main`
  --padding-left: 30px;
  display: grid;
  grid-template-columns: 300px 1fr;
  grid-template-rows: 60px 1fr;
  min-height: 100vh;
  background-color: #0e0e0e;
  color: #fff;
  font-family: "Poppins";
  user-select: none;
`;

const Header = styled.header`
  grid-column: 1 / span 2;
  grid-row: 1;
  background-color: #151515;
  border-bottom: 2px solid #373737;
  font-family: "Inter";
  font-size: 20px;
  font-weight: 400;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-left: var(--padding-left);
  gap: 10px;
`;

const Nav = styled.nav`
  grid-column: 1;
  grid-row: 2;
  display: flex;
  flex-direction: column;
  height: calc(100vh - 50px);
  background-color: #151515;
  border-right: 2px solid #373737;
  padding: 0 var(--padding-left);
`;

const NavTitle = styled.h1`
  margin-top: 150px;
  font-weight: 600;
`;

const NavDescription = styled.p`
  margin-top: 30px;
  font-size: 17px;
  font-weight: 400;
`;

const Content = styled.div`
  grid-column: 2;
  grid-row: 2;
  margin: 50px 250px 0 50px;
`;

const Title = styled.h1`
  font-weight: 500;
`;

const SubTitle = styled.h2`
  font-weight: 500;
  font-size: 20px;
`;

const Container = styled.div`
  width: 100%;
  margin-top: 70px;
`;

const BackButton = styled.button`
  outline: none;
  width: 50px;
  height: 50px;
  outline: none;
  border: 2px solid #373737;
  border-radius: 10px;
  background:
    center no-repeat url(${assets.buttons.LeftArrow}),
    #1a1a1a;
  cursor: pointer;
  &:hover {
    background-color: #fff1;
  }
`;

const RowContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: end;
  flex-wrap: wrap;
  margin-bottom: 30px;
  width: 100%;
`;

const WalletsContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  column-gap: 30px;
  row-gap: 30px;
  width: 100%;
`;

const WalletButton = styled.button<{ $activating: boolean }>`
  display: flex;
  align-items: center;
  gap: 20px;
  padding-left: 20px;
  /* width: ${(props) => (props.$activating ? "100%" : "300px")}; */
  grid-column: ${(props) => props.$activating && "1/-1"};
  height: 85px;
  background-color: #1a1a1a;
  border: 2px solid #373737;
  border-radius: 10px;
  outline: none;
  cursor: pointer;
  &:hover {
    background-color: #fff1;
  }
`;

const WalletName = styled.p`
  color: #fff;
  font-size: 17px;
`;

export type Props = {
  // connections: Record<string, Connection>;
  // connect?: (key: string, connection: Connection) => void | Promise<void>;
  connectors: [Connector, Web3ReactHooks][];
  setConnect: () => void;
};

function Creation({ connectors, setConnect }: Props): JSX.Element {
  const navigate = useNavigate();
  const { connector: currConnector } = useWeb3React();

  return (
    <Main>
      <Header>
        <img src={assets.UroborosLogo} alt="uroboros-logo" />
        <p>Uroboros</p>
      </Header>
      <Nav>
        <NavTitle>Connect your wallet</NavTitle>
        <NavDescription>
          Connecting your wallet is like “logging in” to Web3. Select your
          wallet from the options to get started.
        </NavDescription>
      </Nav>
      <Content>
        <Title>Connect wallet</Title>
        <Container>
          <RowContainer>
            <SubTitle>Available wallets</SubTitle>
            <BackButton
              onClick={() => {
                navigate("/");
                ga.event({
                  category: "Login",
                  action: "Clicked 'Back' button",
                });
              }}
            />
          </RowContainer>
          <WalletsContainer>
            {connectors.map(([connector], key) => {
              // console.log({ connector, tag: (connector as any).__tag });
              let { title, image } = CONNECTOR_INFO[(connector as any).__tag];
              return (
                <WalletButton
                  key={key}
                  onClick={async () => {
                    ga.event({
                      category: "Login",
                      action: "Clicked 'Connect' button",
                      label: title,
                    });
                    setConnect();
                    await currConnector.deactivate?.();
                    await connector?.activate();
                  }}
                  $activating={false}
                >
                  <img src={image} alt={title} />
                  <WalletName>{title}</WalletName>
                </WalletButton>
              );
            })}
          </WalletsContainer>
        </Container>
      </Content>
    </Main>
  );
}

export default Creation;
