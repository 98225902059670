import { BigNumberish } from "@ethersproject/bignumber";
import { apiV2 } from "@uroboros-labs/wallet-sdk";
import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import ga from "react-ga";
import { styled } from "styled-components";
import { useDebounce, useToggle } from "usehooks-ts";
import Assets from "../assets";
import { ApiContext } from "../context";
import useRoute from "../hooks/useRoute";
import { NETWORKS } from "../networks";
import GasSelect from "./GasSelect";
import MultiSelect from "./MultiSelect";
import SelectAsset, { AssetLike } from "./SelectAsset";
import SelectNetwork from "./SelectNetwork";
import { Button } from "./SendModal";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 25px;
  gap: 10px;
  border-radius: 30px;
  min-height: 300px;
  max-height: 750px;
  overflow-y: scroll;
  width: 500px;
  background-color: #151515;
  border: 2px solid #404040;
  user-select: none;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 15px;
  width: 100%;
`;

const Title = styled.h1`
  font-family: "Poppins";
  font-weight: 500;
  font-size: 23px;
  color: #fff;
  align-self: center;
`;

export const ErrorText = styled.p`
  font-family: "DM Sans", sans-serif;
  font-weight: 400;
  font-size: 20px;
  color: #ff0420;
  text-align: center;
`;

export const WarningText = styled(ErrorText)`
  color: #ffdd2d;
`;

const SwapButton = styled.button<{ $swap: boolean }>`
  display: block;
  width: 40px;
  height: 40px;
  margin: -30px 0;
  z-index: 1;
  background:
    url(${Assets.Exchange}) no-repeat center / 60%,
    #1d1d1d;
  border: 2px solid #404040;
  border-radius: 10px;
  align-self: center;
  cursor: pointer;
  transform: ${(props) => props.$swap && "rotate(180deg)"};
  transition: transform 100ms ease-in-out;
`;

export const RouteStats = styled.div`
  --padding: 10px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  width: calc(100% - var(--padding) * 2);
  gap: 7px;
  padding: 10px;
`;

export const StatRow = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const StatKey = styled.p<{ $warning?: boolean }>`
  font-family: "Inter";
  font-weight: 400;
  font-size: 20px;
  color: ${(props) => (props.$warning ? "#ffdd2d" : "#fefefe")};
`;

export const Stat = styled(StatKey)`
  /* color: ${(props) => (props.$warning ? "#ffdd2d" : "#404040")}; */
`;

export type SelectNetworkState = {
  type: "select/network";
  multiple?: boolean;
  selectedDefault?: Set<number> | number;
  onSelect: (selected: Set<number> | number) => void;
};

export type SelectAssetState = {
  type: "select/asset";
  assets: AssetLike[];
  onSelect: (asset: AssetLike) => void;
  isWarning?: (asset: AssetLike) => boolean;
};

export type State = SelectNetworkState | SelectAssetState;

export type SwapModalProps = {
  assets: apiV2.AssetOrToken[];
  account?: string;
  onSend?: (tx: { chainId: BigNumberish; hash: string }[]) => void;
};

const ALL_NETWORKS = new Set(NETWORKS.keys());

function SwapModal({ assets, account, onSend }: SwapModalProps): JSX.Element {
  useEffect(() => {
    ga.modalview("swap");
    return () =>
      ga.event({
        category: "Swap/Send",
        action: "Closed swap",
      });
  }, []);

  const [state, setState] = useState<State | undefined>();

  const [sourceAmount, setSourceAmount] = useState(0);
  // const [destAmount, setDestAmount] = useState(0);
  const debouncedAmount = useDebounce(sourceAmount, 300);

  const [sourceAsset, setSourceAsset] = useState<
    apiV2.AssetOrToken | undefined
  >(() => assets?.[0]);
  const [destCoin, setDestCoin] = useState<apiV2.Coin>();
  const [gasAsset, setGasAsset] = useState<apiV2.AssetOrToken | undefined>(
    () => assets?.find((asset) => (asset.gas ?? 0) > 0),
  );

  const [sourceNetworks, setSourceNetworks] = useState(ALL_NETWORKS);
  const [destNetworks, setDestNetworks] = useState(ALL_NETWORKS);

  const [swap, toggleSwap] = useToggle(false);

  const { coins } = useContext(ApiContext);

  const _srcNetworks = useMemo(() => {
    return sourceNetworks !== undefined
      ? Array.from(sourceNetworks).map((network) => NETWORKS[network])
      : undefined;
  }, [sourceNetworks]);

  const _destNetworks = useMemo(() => {
    return destNetworks !== undefined
      ? Array.from(destNetworks).map((network) => NETWORKS[network])
      : undefined;
  }, [destNetworks]);

  let {
    amountOut,
    amountFeeGas,
    send,
    message,
    amountInSwapped,
    price,
    amountInSwappedLess,
  } = useRoute({
    gas: gasAsset,
    asset: sourceAsset,
    coin: destCoin,
    amount: debouncedAmount.toString(),
    recipient: account,
    srcNetworks: _srcNetworks,
    destNetworks: _destNetworks,
    slippage: 1,
    sender: account,
  });

  // useEffect(() => console.log({ response, error }), [error, response]);

  // useEffect(() => {
  //   if (response !== undefined) {
  //     setDestAmount(response.amountOut);
  //   }
  // }, [response]);

  const [pending, setPending] = useState(false);

  const swapAssets = useCallback(() => {
    let newSourceAsset: apiV2.AssetOrToken | undefined;
    let newDestCoin: apiV2.Coin | undefined;
    if (destCoin !== undefined) {
      newSourceAsset = assets.find(
        (asset) => asset.type === "asset" && asset.rawId === destCoin.rawId,
        // (asset) => asset.type === "asset" && Object.is(asset, destCoin),
      );
      if (newSourceAsset === undefined) return false;
    }
    if (sourceAsset !== undefined && sourceAsset.type === "asset") {
      newDestCoin = coins?.find((coin) => coin.rawId === sourceAsset.rawId);
      // newDestCoin = coins?.find((coin) => Object.is(coin, sourceAsset));
      if (newDestCoin === undefined) return false;
    }
    setSourceAsset(newSourceAsset);
    setDestCoin(newDestCoin);
    return true;
  }, [assets, coins, destCoin, sourceAsset]);

  const content = useMemo(() => {
    switch (state?.type) {
      case "select/network":
        return (
          <SelectNetwork
            multiple
            networks={NETWORKS}
            selectedDefault={state.selectedDefault}
            onSelect={state.onSelect}
            onClose={() => setState(undefined)}
          />
        );
      case "select/asset":
        return (
          <SelectAsset
            assets={state.assets}
            onSelect={(asset) => {
              // @ts-ignore
              state.onSelect(asset);
              setState(undefined);
            }}
            onClose={() => setState(undefined)}
            isWarning={state.isWarning}
          />
        );
      default:
        return (
          <Content>
            <Title>Swap</Title>
            <MultiSelect
              defaultValue={sourceAmount}
              onValueChange={setSourceAmount}
              network={Array.from(sourceNetworks).map(
                (index) => NETWORKS[index],
              )}
              asset={sourceAsset}
              onNetworkClick={() =>
                setState({
                  type: "select/network",
                  selectedDefault: sourceNetworks,
                  onSelect: (selected) => {
                    if (typeof selected !== "number") {
                      setSourceNetworks(selected);
                    }
                  },
                })
              }
              onAssetClick={() =>
                setState({
                  type: "select/asset",
                  // @ts-ignore
                  onSelect: setSourceAsset,
                  assets: assets.filter(
                    (asset) =>
                      asset.type !== "asset" || asset.rawId !== destCoin?.rawId,
                    // asset.type !== "asset" || !Object.is(asset, destCoin),
                  ),
                })
              }
            />
            <SwapButton
              $swap={swap}
              onClick={() => {
                if (swapAssets()) {
                  toggleSwap();
                }
              }}
            />
            <MultiSelect
              type="receive"
              // fixedValue={destAmount.toFixed(5)}
              fixedValue={amountOut}
              network={Array.from(destNetworks).map((index) => NETWORKS[index])}
              asset={destCoin}
              onAssetClick={() =>
                setState({
                  type: "select/asset",
                  // @ts-ignore
                  onSelect: setDestCoin,
                  assets: coins?.filter(
                    (coin) =>
                      sourceAsset?.type !== "asset" ||
                      coin.id !== sourceAsset?.id,
                  ) as AssetLike[],
                })
              }
              balance={
                destCoin !== undefined
                  ? assets.find(
                      (asset) =>
                        asset.type === "asset" &&
                        asset.rawId === destCoin.rawId,
                      // asset.type === "asset" && Object.is(asset, destCoin),
                    )?.amount
                  : 0 ?? 0
              }
              onNetworkClick={() =>
                setState({
                  type: "select/network",
                  selectedDefault: destNetworks,
                  onSelect: (selected) => {
                    if (typeof selected !== "number") {
                      setDestNetworks(selected);
                    }
                  },
                })
              }
            />
            <GasSelect
              // amount={gasFee?.toFixed(5)}
              amount={amountFeeGas}
              selected={gasAsset}
              onSelectClick={() =>
                setState({
                  type: "select/asset",
                  // @ts-ignore
                  onSelect: setGasAsset,
                  assets: assets.filter(
                    (asset) => ((asset as apiV2.AssetOrToken).gas ?? 0) > 0,
                  ),
                  isWarning: (asset) => (asset as apiV2.Asset).gas === 1,
                })
              }
            />
            <RouteStats>
              {amountInSwappedLess && (
                <StatRow>
                  <StatKey $warning>Max Swappable Amount:</StatKey>
                  <Stat $warning>{amountInSwapped ?? "0.0"}</Stat>
                </StatRow>
              )}
              <StatRow>
                <StatKey>Exchange Rate:</StatKey>
                <Stat>{price ?? "0.0"}</Stat>
              </StatRow>
            </RouteStats>
            {message &&
              (message.type === "error" ? (
                <ErrorText>{message.message}</ErrorText>
              ) : (
                <WarningText>{message.message}</WarningText>
              ))}

            <Button
              $pending={pending}
              onClick={async () => {
                ga.event({
                  category: "Swap/Send",
                  action: "Clicked 'Swap' button",
                });
                setPending(true);
                try {
                  let tx = await send();
                  if (tx !== undefined && tx.length > 0) {
                    onSend?.(tx);
                  }
                } finally {
                  setPending(false);
                }
              }}
            >
              Swap
            </Button>
          </Content>
        );
    }
  }, [
    amountFeeGas,
    amountInSwapped,
    amountInSwappedLess,
    amountOut,
    assets,
    coins,
    destCoin,
    destNetworks,
    gasAsset,
    message,
    onSend,
    pending,
    price,
    send,
    sourceAmount,
    sourceAsset,
    sourceNetworks,
    state,
    swap,
    swapAssets,
    toggleSwap,
  ]);

  return <Container>{content}</Container>;
}

export default SwapModal;
