import { styled } from "styled-components";
import assets from "../assets";
import Popup from "reactjs-popup";
import SessionsModal, { SessionView } from "./SessionsModal";
import { createRef } from "react";
import ga from "react-ga";
import { parseUri } from "@walletconnect/utils";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 25px;
  padding: 0 30px;
`;

const Description = styled.p`
  text-align: center;
  font-size: 15px;
`;

const InputContainer = styled.div`
  width: 100%;
  display: flex;
  gap: 15px;
`;

const Input = styled.input`
  --image-padding: 40px;
  flex: 1;
  /* width: calc(100% - var(--image-padding)); */
  text-overflow: ellipsis;
  height: 40px;
  background: url(${assets.buttons.QrCode}) no-repeat 7px / 25px;
  border: 2px solid #404040;
  color: #404040;
  border-radius: 10px;
  font-family: "Poppins";
  font-size: 17px;
  outline: none;
  padding-left: var(--image-padding);
  &::placeholder {
    color: #404040;
  }
  &::selection {
    background-color: #fff1;
  }
`;

const SessionsButton = styled.button`
  height: 40px;
  width: 40px;
  border-radius: 10px;
  outline: none;
  border: none;
  background:
    url(${assets.Link}) center / 60% no-repeat,
    #2dfaef;
  cursor: pointer;
  &:hover {
    opacity: 0.9;
  }
`;

export type WalletConnectProps = {
  sessions?: SessionView[];
  onUriChange?: (uri: string) => boolean | Promise<boolean>;
};

// const sessions: SessionView[] = new Array(10).fill({
//   peerMeta: {
//     name: "Uniswap",
//     description: "Uniswap",
//     url: "https://app.uniswap.com",
//     icons: [assets.UroborosLogo],
//   },
//   disconnect: async () => {},
// });

function WalletConnect({
  sessions,
  onUriChange,
}: WalletConnectProps): JSX.Element {
  const ref = createRef<HTMLInputElement>();
  return (
    <Container>
      <img
        src={assets.wallets.WalletConnectBlueLogo}
        alt="wallet-connect-blue-logo"
      />
      <Description>
        Connect to a dApp via the WalletConnect and trigger transactions
      </Description>
      <InputContainer>
        <Input
          ref={ref}
          placeholder="QR code or connection link"
          onChange={async (e) => {
            ga.event({
              category: "Connect",
              action: "Entered url",
            });
            if (await onUriChange?.(e.target.value)) {
              let curr = ref.current;
              if (curr !== null) {
                curr.value = "";
              }
            }
          }}
        />
        {sessions && sessions.length > 0 && (
          <Popup
            modal
            trigger={<SessionsButton />}
            overlayStyle={{ background: "#fff3" }}
          >
            <SessionsModal sessions={sessions} />
          </Popup>
        )}
      </InputContainer>
    </Container>
  );
}

export default WalletConnect;
