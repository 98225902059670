import { BigNumber } from "@ethersproject/bignumber";
import { z } from "zod";

export const TransactionRequest = z.object({
  to: z.string(),
  from: z.string().optional(),
  data: z.string().optional(),
  value: z.string().optional(),
  gas: z.string().optional(),
  gasPrice: z.string().optional(),
});

export const Request = z.discriminatedUnion("method", [
  z.object({
    method: z.literal("eth_getCode"),
    params: z.tuple([z.string(), z.string()]),
  }),
  z.object({
    method: z.literal("eth_sendTransaction"),
    params: z.tuple([TransactionRequest]),
  }),
  z.object({
    method: z.literal("wallet_switchEthereumChain"),
    params: z.tuple([
      z.object({
        chainId: z.string().transform(BigNumber.from),
      }),
    ]),
  }),
]);
