import Arbitrum from "./Arbitrum.svg";
import ArrowDown from "./ArrowDown.svg";
import Avalanche from "./Avalanche.svg";
import CoinbaseLogo from "./Coinbase.svg";
import Dashboard from "./Dashboard.svg";
import Disabled from "./Disabled.svg";
import Discord from "./Discord.svg";
import Eth from "./Eth.svg";
import Github from "./Github.svg";
import Warning from "./Warning.svg";
import Link from "./Link.svg";
import LeftArrow from "./LeftArrow.svg";
import Loading from "./Loading.svg";
import Medium from "./Medium.svg";
import USDCoin from "./USDCoin.svg";
import Dai from "./Dai.svg";
import MetaMaskLogo from "./MetaMask.svg";
import Optimism from "./Optimism.svg";
import QrCode from "./QrCode.svg";
import Receive from "./Receive.svg";
import Scroll from "./Scroll.svg";
import Search from "./Search.svg";
import Send from "./Send.svg";
import Swap from "./Swap.svg";
import Telegram from "./Telegram.svg";
import Tether from "./Tether.svg";
import Transactions from "./Transactions.svg";
import Twitter from "./Twitter.svg";
import Bnb from "./Bnb.svg";
import Polygon from "./Polygon.svg";
import UroborosLogo from "./Uroboros.svg";
import BlankWallet from "./Wallet.svg";
import WalletConnectLogo from "./WalletConnect.svg";
import WalletConnectBlueLogo from "./WalletConnectBlue.svg";
import BackArrow from "./BackArrow.svg";
import GasStation from "./GasStation.svg";
import Exchange from "./Exchange.svg";
import AllNetworks from "./AllNetworks.svg";

const assets = {
  UroborosLogo,
  // icons
  Disabled,
  Loading,
  BlankWallet,
  BackArrow,
  GasStation,
  Link,
  AllNetworks,
  Warning,
  // chains
  Optimism,
  Arbitrum,
  Avalanche,
  Bnb,
  Polygon,
  Eth,
  // buttons
  Exchange,
  // tokens
  USDCoin,
  Dai,
  wallets: {
    MetaMaskLogo,
    CoinbaseLogo,
    WalletConnectLogo,
    WalletConnectBlueLogo,
  },
  tokens: {
    Tether,
  },
  buttons: {
    Dashboard,
    Transactions,
    LeftArrow,
    QrCode,
    Swap,
    Send,
    Receive,
    Search,
    Scroll,
    ArrowDown,
  },
  socials: {
    Twitter,
    Discord,
    Medium,
    Telegram,
    Github,
  },
};

export default assets;
