import { apiV2 } from "@uroboros-labs/wallet-sdk";
import { createContext } from "react";

export const LoadingContext = createContext<boolean>(false);

export type ApiContext = {
  coins?: apiV2.Coin[];
  setCoins?: (coins: apiV2.Coin[] | undefined) => void;
};

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ApiContext = createContext<ApiContext>({});
