import { useEffect, useState } from "react";
import QRCode from "react-qr-code";
import { styled } from "styled-components";
import { Button } from "./SendModal";
import ga from "react-ga";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 500px;
  gap: 25px;
  min-height: 300px;
  padding: 25px;
  background-color: #151515;
  border: 2px solid #404040;
  border-radius: 15px;
  user-select: none;
`;

const Title = styled.h1`
  font-family: "Poppins";
  font-weight: 400;
  color: #fff;
  align-self: start;
  margin-bottom: 0;
`;

const Description = styled.p`
  font-family: "Poppins";
  color: #fff;
`;

const Address = styled.p`
  font-family: "DM Sans", sans-serif;
  font-weight: 400;
  font-size: 20px;
  color: #fff;
  user-select: text;
  &::selection {
    background-color: #404040;
  }
`;

const CopiedText = styled.p`
  font-family: "DM Sans", sans-serif;
  font-weight: 400;
  font-size: 17px;
  color: #6ffa2d;
`;

export type ReceiveModalProps = {
  account: string;
};

function ReceiveModal({ account }: ReceiveModalProps): JSX.Element {
  const [pending, setPending] = useState(false);
  const [copied, setCopied] = useState(false);
  useEffect(() => {
    ga.modalview("receive");
    return () =>
      ga.event({
        category: "Account",
        action: "Closed receive",
      });
  }, []);
  useEffect(() => {
    if (copied === true) {
      let timeout = setTimeout(() => setCopied(false), 1000);
      return () => clearTimeout(timeout);
    }
  }, [copied]);
  return (
    <Container>
      <Title>Receive</Title>
      <Description>
        This is the address of your Uroboros account, use it on any of our
        supported EVM networks.
      </Description>
      <QRCode value={account} fgColor="#fff" bgColor="transparent" />
      <Address>{account}</Address>
      <Button
        $pending={pending}
        onClick={async () => {
          ga.event({
            category: "Account",
            action: "Copied account address",
          });
          setCopied(false);
          setPending(true);
          await navigator.clipboard.writeText(account);
          setPending(false);
          setCopied(true);
        }}
      >
        Copy
      </Button>
      {copied && <CopiedText>Copied !</CopiedText>}
    </Container>
  );
}

export default ReceiveModal;
