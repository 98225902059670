import { BigNumberish } from "@ethersproject/bignumber";
import { Core } from "@walletconnect/core";
import { JsonRpcError, JsonRpcResult } from "@walletconnect/jsonrpc-types";
import { SessionTypes } from "@walletconnect/types";
import {
  formatAccountWithChain,
  getSdkError,
  parseChainId,
  parseUri,
} from "@walletconnect/utils";
import { Web3Wallet, Web3WalletTypes } from "@walletconnect/web3wallet";
import { useCallback, useEffect, useState } from "react";
import { EIP155_CHAINS } from "../networks";

const VERSION_V2 = 2;

const core = new Core({
  projectId: "16f0a1245593d98dc5baf7779fd23d16",
});

const web3WalletInit = Web3Wallet.init({
  core,
  metadata: {
    name: "Uroboros",
    description: "Uroboros omnichain wallet",
    url: "https://www.uroboros.co/",
    icons: [],
  },
});

export type Request<T = any> = {
  session: SessionTypes.Struct;
  chainId: BigNumberish;
  request: {
    method: string;
    params: T;
  };
};

type Response<T = any> =
  | Pick<JsonRpcResult<T>, "result">
  | Pick<JsonRpcError, "error">;

export type RequestHandler = (request: Request) => Promise<Response> | Response;

export default function useWalletConnect(
  accounts: string[],
  requestHandler: RequestHandler,
) {
  let [web3Wallet, setWeb3Wallet] = useState<Awaited<typeof web3WalletInit>>();
  let [sessions, setSessions] = useState<SessionTypes.Struct[]>([]);

  useEffect(() => {
    web3WalletInit.then(setWeb3Wallet);
  }, []);

  useEffect(() => {
    if (web3Wallet !== undefined) {
      let sessions = Object.values(web3Wallet.getActiveSessions());
      setSessions(sessions);
    }
  }, [web3Wallet]);

  // session_proposal handler, depends: web3Wallet, accounts
  useEffect(() => {
    if (web3Wallet === undefined) {
      return;
    }
    let sessionProposalHandler = async (
      args: Web3WalletTypes.SessionProposal,
    ) => {
      console.log({ session_proposal: args });
      if (web3Wallet === undefined) {
        return;
      }
      let eip155Namespace = args.params.requiredNamespaces["eip155"];
      console.log({ eip155Namespace });
      if (eip155Namespace?.chains === undefined) {
        return;
      }
      // let { chains } = eip155Namespace;
      let session = await web3Wallet.approveSession({
        id: args.id,
        namespaces: {
          eip155: {
            // chains: eip155Namespace.chains,
            chains: EIP155_CHAINS,
            methods: [...eip155Namespace.methods, "wallet_switchEthereumChain"],
            events: eip155Namespace.events,
            accounts: accounts.flatMap((account) =>
              // chains.map((chain) => formatAccountWithChain(account, chain)),
              EIP155_CHAINS.map((chain) =>
                formatAccountWithChain(account, chain),
              ),
            ),
          },
        },
      });
      setSessions((prevSessions) => [...prevSessions, session]);
    };
    web3Wallet.on("session_proposal", sessionProposalHandler);
    return () => {
      if (web3Wallet !== undefined) {
        console.log("remove: session_proposal");
        web3Wallet.removeListener("session_proposal", sessionProposalHandler);
      }
    };
  }, [accounts, web3Wallet]);

  // session_delete handler, depens: web3Wallet
  useEffect(() => {
    if (web3Wallet === undefined) {
      return;
    }
    let sessionDeleteHandler = (
      args: Omit<Web3WalletTypes.BaseEventArgs<unknown>, "params">,
    ) => {
      console.log({ session_delete: args });
      setSessions((sessions) => {
        console.log({ sessions });
        let index = sessions.findIndex(
          (session) => session.topic === args.topic,
        );
        console.log({ index });
        if (index !== -1) {
          sessions = [...sessions];
          sessions.splice(index, 1);
        }
        return sessions;
      });
    };
    web3Wallet.on("session_delete", sessionDeleteHandler);
    return () => {
      if (web3Wallet !== undefined) {
        console.log("remove: session_delete");
        web3Wallet.removeListener("session_delete", sessionDeleteHandler);
      }
    };
  }, [web3Wallet]);

  // session_request handler, depends: web3Wallet, requestHandler, accounts
  useEffect(() => {
    if (web3Wallet === undefined) {
      return () => {};
    }
    let sessionRequestHandler = async (
      args: Web3WalletTypes.SessionRequest,
    ) => {
      console.log({ session_request: args });
      if (web3Wallet === undefined) {
        return;
      }
      let { request, chainId } = args.params;
      let { namespace, reference } = parseChainId(chainId);
      let session = sessions.find((session) => session.topic === args.topic);
      if (namespace !== "eip155" || session === undefined) {
        return;
      }
      let response = await requestHandler({
        session,
        chainId: reference,
        request,
      });
      web3Wallet.respondSessionRequest({
        topic: args.topic,
        response: {
          ...response,
          id: args.id,
          jsonrpc: "2.0",
        },
      });
    };
    web3Wallet.on("session_request", sessionRequestHandler);
    return () => {
      if (web3Wallet !== undefined) {
        console.log("remove: session_request");
        web3Wallet.removeListener("session_request", sessionRequestHandler);
      }
    };
  }, [requestHandler, sessions, web3Wallet]);

  const connect = useCallback(
    async (uri: string) => {
      if (web3Wallet === undefined) {
        return false;
      }
      try {
        var { version } = parseUri(uri);
      } catch {
        return false;
      }
      switch (version) {
        case VERSION_V2:
          try {
            let pairing = await web3Wallet.core.pairing.pair({
              uri,
              activatePairing: true,
            });
            console.log({ pairing });
            return true;
          } catch (error) {
            console.error({ web3Wallet_pair_error: error });
            return false;
          }
        default:
          return false;
      }
    },
    [web3Wallet],
  );

  const disconnect = useCallback(
    async (index: number) => {
      let session = sessions.at(index);
      if (session === undefined || web3Wallet === undefined) {
        return false;
      }
      await web3Wallet.disconnectSession({
        topic: session.topic,
        reason: getSdkError("USER_DISCONNECTED"),
      });
      let newSessions = [...sessions];
      newSessions.splice(index, 1);
      setSessions(newSessions);
      return true;
    },
    [sessions, web3Wallet],
  );

  return {
    sessions,
    connect,
    disconnect,
  };
}
