import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import ga from "react-ga";

export const Main = styled.main`
  height: 100vh;
  background: radial-gradient(circle at bottom left, #6c469750, transparent 50%),
    radial-gradient(circle at center right, #499a7850, transparent 50%), #000;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-family: "Poppins";
  user-select: none;
`;

export const Title = styled.h1`
  color: #fff;
  font-weight: 700;
  font-size: 70px;
`;

const GradientTitle = styled(Title)`
  background: -webkit-linear-gradient(45deg, #c028ce, #2dfaef 40%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`;

const Description = styled.p`
  color: #9f9f9f;
  font-weight: 500;
  font-size: 20px;
  margin-top: 20px;
`;

const ButtonsContainer = styled.div`
  margin-top: 90px;
  display: flex;
  gap: 50px;
`;

const Button = styled.button`
  height: 60px;
  width: 250px;
  font-weight: 500;
  font-size: 17px;
  cursor: pointer;
  color: #2dfaef;
  background-color: transparent;
  border: 2px solid #2dfaef;
  border-radius: 5px;
  outline: none;
`;

const FillButton = styled(Button)`
  background-color: #2dfaef;
  color: #000;
`;

function Home(): JSX.Element {
  const navigate = useNavigate();

  return (
    <Main>
      <Title>WELCOME TO THE</Title>
      <GradientTitle>UROBOROS WALLET</GradientTitle>
      <Description>
        Gasless, multi-chain and completely trustless crypto storage
      </Description>
      <ButtonsContainer>
        <Button
          onClick={() => {
            navigate("/creation");
            ga.event({
              category: "Login",
              action: "Clicked 'Login' button",
            });
          }}
        >
          Log in
        </Button>
        <FillButton
          onClick={() => {
            navigate("/creation");
            ga.event({
              category: "Login",
              action: "Clicked 'Create new' button",
            });
          }}
        >
          Create new
        </FillButton>
      </ButtonsContainer>
    </Main>
  );
}

export default Home;
