import { BigNumber, BigNumberish } from "@ethersproject/bignumber";
import { Account, Notification, apiV2 } from "@uroboros-labs/wallet-sdk";
import { useWeb3React } from "@web3-react/core";
import BlockiesSvg from "blockies-react-svg";
import { useCallback, useEffect, useState } from "react";
import {
  NavigateOptions,
  Outlet,
  useLocation,
  useNavigate,
} from "react-router-dom";
import Popup from "reactjs-popup";
import { styled } from "styled-components";
import { useMap } from "usehooks-ts";
import { RELAY_SUBSCRIPTIONS } from "../api";
import assets from "../assets";
import ConnectionModal from "../components/ConnectionModal";
import { Loading } from "../components/Loading";
import PendingModal from "../components/PendingModal";
import ReceiveModal from "../components/ReceiveModal";
import SendModal from "../components/SendModal";
import SwapModal from "../components/SwapModal";
import TransactionModal from "../components/TransactionModal";
import WalletConnect from "../components/WalletConnect";
import { CONNECTOR_INFO } from "../connections";
import useAccount from "../hooks/useAccount";
import useRequestHandler, { TransactionArgs } from "../hooks/useRequestHandler";
import useWalletConnect from "../hooks/useWalletConnect";
import { unlocked } from "../unlocker";
import { NETWORKS, Network } from "../networks";
import SettledTxModal, { SettledTxView } from "../components/SettledTxModal";
import { formatHash } from "./Transactions";
import ga from "react-ga";

const Main = styled.main`
  --padding-left: 50px;
  --nav-width: 350px;
  display: grid;
  grid-template-columns: var(--nav-width) 1fr;
  grid-template-rows: 60px 1fr;
  height: 100vh;
  background-color: #0e0e0e;
  color: #fff;
  font-family: "Poppins";
  user-select: none;
`;

const Header = styled.header`
  grid-column: 1 / span 2;
  grid-row: 1;
  background-color: #151515;
  border-bottom: 2px solid #373737;
  padding-left: var(--padding-left);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

const HeaderLogoContainer = styled.button`
  font-family: "Inter";
  font-size: 20px;
  font-weight: 400;
  display: flex;
  align-items: center;
  gap: 10px;
  background: none;
  outline: none;
  border: none;
  cursor: pointer;
  color: #fff;
`;

const HeaderControlsContainer = styled.div`
  display: flex;
  height: 100%;
`;

const HeaderControlContainer = styled.div`
  width: 200px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-left: 2px solid #373737;
  &:hover {
    background-color: #fff1;
  }
`;

const HeaderPendingControl = styled.button`
  width: 150px;
  padding: 3px;
  background: none;
  outline: none;
  border: 3px solid #6ffa2d;
  border-radius: 5px;
  color: #6ffa2d;
  font-family: "Poppins";
  font-size: 17px;
  font-weight: 500;
  cursor: pointer;
`;

const HeaderConnectionControl = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  cursor: pointer;
`;

const HeaderConnectionControlImage = styled.img`
  width: 40px;
  height: 40px;
`;

const Nav = styled.nav`
  grid-column: 1;
  grid-row: 2;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 100px 100px 150px 1fr 70px;
  background-color: #151515;
  border-right: 2px solid #373737;
`;

const NavControlsContainer = styled.div`
  grid-column: 1;
  grid-row: 3;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
`;

const NavControl = styled.button<{ $selected: boolean }>`
  background-color: transparent;
  display: flex;
  align-items: center;
  gap: 15px;
  height: 70px;
  border: none;
  outline: none;
  color: ${(props) => (props.$selected ? "#e6e6e6" : "#b9b9b9")};
  font-size: 22px;
  font-family: "Poppins";
  font-weight: 500;
  padding-left: var(--padding-left);
  cursor: pointer;

  & img {
    width: 30px;
    height: 30px;
  }

  &:hover {
    background-color: #fff1;
  }

  &::before {
    content: "";
    --width: 3px;
    left: calc(var(--nav-width) - var(--width));
    position: absolute;
    border-radius: var(--width) 0 0 var(--width);
    display: ${(props) => (props.$selected ? "block" : "none")};
    width: var(--width);
    height: 35px;
    background-color: #2dfaefed;
  }
`;

const NavControlImg = styled.div<{ $url: string; $selected: boolean }>`
  display: block;
  width: 30px;
  height: 30px;
  background-color: ${(props) => (props.$selected ? "#2dfaef" : "#b9b9b9")};
  mask: ${(props) => `url(${props.$url}) no-repeat center / 100%`};
  -webkit-mask: ${(props) => `url(${props.$url}) no-repeat center / 100%`};
`;

const WalletConnectContainer = styled.div`
  grid-column: 1;
  grid-row: 4;
  display: flex;
  align-items: center;
`;

const SocialsContainer = styled.div`
  grid-column: 1;
  grid-row: 5;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 50px;
  border-top: 2px solid #434343;
`;

const SocialImg = styled.img`
  width: 35px;
  height: 35px;
  cursor: pointer;

  &:hover {
    opacity: 0.7;
  }
`;

const ActionButtonsContainer = styled.div`
  grid-column: 1;
  grid-row: 2;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 var(--padding-left);
  border-bottom: 2px solid #434343;
`;

const ActionButton = styled.button<{ $url: string }>`
  background: ${(props) => `url(${props.$url}) no-repeat center / 35%`};
  outline: none;
  border: 2px solid #2dfaef;
  width: 60px;
  height: 60px;
  border-radius: 10px;
  cursor: pointer;
  &:hover {
    opacity: 0.7;
  }
`;

const AccountContainer = styled.div`
  grid-column: 1;
  grid-row: 1;
  padding: 0 var(--padding-left);
  display: flex;
  align-items: center;
  gap: 20px;
`;

const Content = styled.div`
  grid-column: 2;
  grid-row: 2;
  margin: 50px 100px 0 50px;
  overflow-y: scroll;
  overflow-x: hidden;
  scroll-behavior: smooth;
  -ms-overflow-style: none;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
`;

const Avatar = styled(BlockiesSvg)`
  width: 60px;
  height: 60px;
  border-radius: 50%;
`;

function formatAddress(address: string): string {
  return address.replace(/(0x.{4}).*(.{4})/, "$1...$2");
}

type Control = {
  title: string;
  image: string;
  to: string;
  options?: NavigateOptions;
};

const controls: Control[] = [
  {
    title: "Dashboard",
    image: assets.buttons.Dashboard,
    to: "dashboard",
  },
  {
    title: "Transactions",
    image: assets.buttons.Transactions,
    to: "transactions",
  },
];

type Social = {
  name: string;
  href: string;
  icon: string;
};

const socials: Social[] = [
  {
    name: "Twitter",
    href: "https://twitter.com/uroborosdefi",
    icon: assets.socials.Twitter,
  },
  {
    name: "Discord",
    href: "https://discord.com/invite/BqtnzHmYwH",
    icon: assets.socials.Discord,
  },
  {
    name: "Medium",
    href: "https://medium.com/@uroborosdefi",
    icon: assets.socials.Medium,
  },
  {
    name: "Telegram",
    href: "https://t.me/uroborosdefi",
    icon: assets.socials.Telegram,
  },
  {
    name: "Github",
    href: "https://github.com/uroboros-labs",
    icon: assets.socials.Github,
  },
];

export type PendingTx = {
  chainId: BigNumberish;
  hash: string;
  enqueued?: boolean;
  txHash?: string;
};

export type InterfaceOutletContext = {
  account?: Account;
  accountV2?: apiV2.Account;
  assets?: apiV2.AssetOrToken[];
  transactions?: apiV2.Transaction[];
};

function Interface(): JSX.Element {
  const navigate = useNavigate();
  const location = useLocation();

  const [controlIndex, setControlIndex] = useState(() => {
    let index = controls.findIndex((control) =>
      location.pathname.endsWith(control.to),
    );
    return index !== -1 ? index : 0;
  });

  useEffect(() => {
    let control = controls[controlIndex];
    if (control !== undefined) {
      navigate(control.to, control.options);
    }
  }, [controlIndex, navigate]);

  const {
    account: wallet,
    owner,
    assets: assetsV2,
    transactions,
  } = useAccount();

  useEffect(() => {
    let controller = new AbortController();
    if (owner === undefined) return () => {};
    unlocked(owner, { signal: controller.signal })
      .then((isUnlocked) => {
        console.log({ isUnlocked });
        if (!isUnlocked) navigate("/code");
      })
      .catch(() => {});
    return () => controller.abort();
  }, [navigate, owner]);

  // TODO: transaction queue
  const [transaction, setTransaction] = useState<TransactionArgs>();

  const requestHandler = useRequestHandler({
    setTransaction,
  });

  // 3 states (w/o LayerZero)
  // 1. pending: urbTxHash, chainId
  // 2. enqueued
  // 3. submitted: txHash

  const [txMap, setTxMap] = useMap<string, BigNumberish>();
  const [pendingTx, setPendingTx] = useMap<
    string,
    { network: Network; description: string }
  >();
  const [settledTx, setSettledTx] = useMap<string, SettledTxView>();

  useEffect(() => console.log({ txMap }), [txMap]);
  useEffect(() => console.log({ pendingTx }), [pendingTx]);
  useEffect(() => console.log({ settledTx }), [settledTx]);

  const onSend = useCallback(
    (tx: { chainId: BigNumberish; hash: string }[]) =>
      tx.forEach(({ chainId, hash }) => setTxMap.set(hash, chainId)),
    [setTxMap],
  );

  // TODO: user transactions (listens to all transactions)
  useEffect(() => {
    let handler = ({ hash, statusMessage }: Notification) => {
      let chainId = txMap.get(hash);
      let network =
        chainId !== undefined
          ? NETWORKS.find((network) =>
              BigNumber.from(chainId).eq(network.chainId),
            )
          : undefined;
      switch (statusMessage.type) {
        case "enqueued":
          if (network !== undefined) {
            setPendingTx.set(hash, {
              network,
              description: "1/3 Relay: enqueued",
            });
          }
          break;
        case "submitted":
          if (network !== undefined) {
            setPendingTx.set(hash, {
              network,
              description: `2/3 Relay: submitted\n${formatHash(
                statusMessage.transaction_hash,
              )}`,
            });
          }
          break;
        case "mined":
          setTxMap.remove(hash);
          setPendingTx.remove(hash);
          if (network !== undefined) {
            setSettledTx.set(hash, {
              network,
              txHash: statusMessage.receipt.transactionHash,
            });
            setTimeout(() => setSettledTx.remove(hash), 10_000);
          }
          break;
        case "rejected":
          setTxMap.remove(hash);
          setPendingTx.remove(hash);
          let message: string;
          if (statusMessage.reason.type === "invalid-chain") {
            message = "chain not supported";
          } else {
            message = statusMessage.reason.message;
          }
          if (network !== undefined) {
            setSettledTx.set(hash, {
              network,
              error: "rejected: " + message,
            });
            setTimeout(() => setSettledTx.remove(hash), 10_000);
          }
          break;
      }
    };
    RELAY_SUBSCRIPTIONS.addListener("transaction", handler);
    return () => {
      console.log("remove: transaction_handler");
      RELAY_SUBSCRIPTIONS.removeListener("transaction", handler);
    };
  }, [setPendingTx, setSettledTx, setTxMap, txMap]);

  const { account, connector } = useWeb3React();

  const { connect, disconnect, sessions } = useWalletConnect(
    wallet !== undefined ? [wallet.address] : [],
    requestHandler,
  );

  return (
    <Main>
      {transaction && (
        <>
          <Popup
            modal
            open
            overlayStyle={{ backgroundColor: "#fff3" }}
            onClose={() => transaction.reject("User denied request")}
          >
            <TransactionModal
              account={wallet?.address}
              // assets={wallet?.assets}
              network={transaction.network}
              functionName="todo"
              address={transaction.request.to}
              addressAlias="Unknown"
              details={transaction.request}
              resolve={transaction.resolve}
            />
          </Popup>
        </>
      )}
      <SettledTxModal tx={settledTx} />
      <Header>
        <HeaderLogoContainer
          onClick={() => {
            navigate("/");
          }}
        >
          <img src={assets.UroborosLogo} alt="uroboros-logo" />
          <p>Uroboros</p>
        </HeaderLogoContainer>
        <HeaderControlsContainer>
          {pendingTx.size > 0 && (
            <HeaderControlContainer>
              <Popup
                arrow={false}
                offsetY={15}
                overlayStyle={{ backgroundColor: "#fff3" }}
                trigger={() => (
                  <HeaderPendingControl>
                    {pendingTx.size} Pending
                  </HeaderPendingControl>
                )}
              >
                <PendingModal tx={pendingTx} />
              </Popup>
            </HeaderControlContainer>
          )}
          <HeaderControlContainer style={{ width: "230px" }}>
            <Popup
              offsetY={10}
              offsetX={-20}
              overlayStyle={{ backgroundColor: "#fff3" }}
              arrow={false}
              trigger={(open) => (
                <HeaderConnectionControl>
                  <HeaderConnectionControlImage
                    src={
                      account !== undefined
                        ? CONNECTOR_INFO[(connector as any).__tag].image
                        : assets.BlankWallet
                    }
                    alt="wallet"
                  />
                  {account !== undefined ? (
                    <p>
                      {CONNECTOR_INFO[(connector as any).__tag].title}
                      <br />
                      {formatAddress(account)}
                    </p>
                  ) : (
                    <p>
                      Not connected
                      <br />
                      <span style={{ color: "#fa392d" }}>Connect wallet</span>
                    </p>
                  )}
                  <img
                    src={assets.buttons.ArrowDown}
                    alt="arrow-down"
                    style={{
                      transform: open ? "rotate(180deg)" : undefined,
                    }}
                  />
                </HeaderConnectionControl>
              )}
            >
              <ConnectionModal />
            </Popup>
          </HeaderControlContainer>
        </HeaderControlsContainer>
      </Header>
      <Nav>
        <AccountContainer>
          <Loading loading={wallet === undefined}>
            <Avatar address={wallet?.address ?? ""} />
          </Loading>
          <p>
            {wallet !== undefined
              ? formatAddress(wallet.address)
              : "0x0000...0000"}
            <br />
            {wallet !== undefined ? `${wallet.value.toFixed(5)} USD` : "0 USD"}
          </p>
        </AccountContainer>
        <ActionButtonsContainer>
          <Popup
            modal
            overlayStyle={{ backgroundColor: "#fff3" }}
            trigger={<ActionButton $url={assets.buttons.Send} />}
          >
            {/** @ts-ignore */}
            {(close) => (
              <SendModal
                assets={assetsV2 ?? []}
                account={wallet?.address}
                onSend={(tx) => {
                  onSend(tx);
                  console.log({ close: tx });
                  close();
                }}
              />
            )}
          </Popup>
          <Popup
            modal
            overlayStyle={{ backgroundColor: "#fff3" }}
            trigger={<ActionButton $url={assets.buttons.Swap} />}
          >
            {/** @ts-ignore */}
            {(close) => (
              <SwapModal
                assets={assetsV2 ?? []}
                account={wallet?.address}
                onSend={(tx) => {
                  onSend(tx);
                  console.log({ close: tx });
                  close();
                }}
              />
            )}
          </Popup>
          <Popup
            modal
            overlayStyle={{ backgroundColor: "#fff3" }}
            trigger={<ActionButton $url={assets.buttons.Receive} />}
          >
            {wallet && <ReceiveModal account={wallet.address} />}
          </Popup>
        </ActionButtonsContainer>
        <NavControlsContainer>
          {controls.map((control, index) => (
            <NavControl
              key={index}
              $selected={controlIndex === index}
              onClick={() => {
                ga.event({
                  category: "Account",
                  action: "Selected on side menu",
                  label: control.title,
                });
                setControlIndex(index);
              }}
            >
              <NavControlImg
                $url={control.image}
                $selected={controlIndex === index}
              />
              <p>{control.title}</p>
            </NavControl>
          ))}
        </NavControlsContainer>
        <WalletConnectContainer>
          <WalletConnect
            onUriChange={connect}
            sessions={sessions.map((session, i) => ({
              peerMeta: session.peer.metadata,
              disconnect: () => disconnect(i),
            }))}
          />
        </WalletConnectContainer>
        <SocialsContainer>
          {socials.map((social, key) => (
            <a
              key={key}
              href={social.href}
              target="_blank"
              rel="noreferrer"
              onClick={() =>
                ga.event({
                  category: "Socials",
                  action: "Clicked social",
                  label: social.name,
                })
              }
            >
              <SocialImg src={social.icon} alt={social.name} />
            </a>
          ))}
        </SocialsContainer>
      </Nav>
      <Content>
        <Outlet
          context={
            {
              accountV2: wallet,
              assets: assetsV2,
              transactions,
            } satisfies InterfaceOutletContext
          }
        />
      </Content>
    </Main>
  );
}

export default Interface;
