import { BigNumber, BigNumberish } from "@ethersproject/bignumber";
import { parseUnits } from "@ethersproject/units";
import { formatUnits } from "../utills";
import {
  UrbTxMessage,
  apiV2,
  signUrbTxPayload,
  transaction,
} from "@uroboros-labs/wallet-sdk";
import _ from "lodash";
import { useCallback, useEffect, useMemo, useState } from "react";
import { RELAY_CLIENT, TRANSACTION_CLIENT } from "../api";
import { NETWORKS, Network } from "../networks";
import { useWeb3React } from "@web3-react/core";
import { SignatureLike } from "@ethersproject/bytes";
import Decimal from "decimal.js";

export type Props = {
  coin?: apiV2.Coin;
  asset?: apiV2.AssetOrToken;
  gas?: apiV2.AssetOrToken;
  amount?: string;
  recipient?: string;
  sender?: string;
  slippage?: number;
  srcNetworks?: Network[];
  destNetworks?: Network[];
};

type Errors = {
  /// invalid gas token selected, warning: please select another gas token
  gas?: boolean;
  amount?: boolean;
};

export type Message = {
  message: string;
  type: "warning" | "error";
};

// warning is generated with errors

// export type UserError = {
//   code: number;
//   message: string;
// };

// export const GAS_ERR_CODE: number = 1;
// export const AMOUNT_ERR_CODE: number = 2;
// const GAS_ERR_MSG: string = "please select another gas token";

const BASE_10 = BigNumber.from(10);
const DECIMALS: number = 5;

export default function useRoute({
  gas,
  asset,
  coin,
  amount,
  recipient,
  sender,
  slippage,
  srcNetworks,
  destNetworks,
}: Props) {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [gasDecimals, setGasDecimals] = useState<number>();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [sourceDecimals, setSourceDecimals] = useState<number>();
  const [destDecimals, setDestDecimals] = useState<number>();
  const [amountIn, setAmountIn] = useState<BigNumber>();
  const [sources, setSources] = useState<transaction.Source[]>();
  const [destinations, setDestinations] = useState<transaction.Destination[]>();
  // TODO: should be Set, return latest
  // const [userError, setUserError] = useState<UserError>();
  const [errors, setErrors] = useState<Errors>({});

  const [pending, setPending] = useState(false);
  const [response, setResponse] = useState<transaction.GetRouteResponse>();
  const [apiError, setApiError] = useState<Error>();

  // useEffect(() => console.log({ sources }), [sources]);

  useEffect(() => {
    setGasDecimals(undefined);
    setSourceDecimals(undefined);
    setAmountIn(undefined);
    setSources(undefined);
    if (
      asset === undefined ||
      amount === undefined ||
      gas === undefined ||
      srcNetworks === undefined
    ) {
      return;
    }
    if (asset.type === "token") {
      let _gas: transaction.Gas | undefined;
      if (!Object.is(asset, gas)) {
        let token: apiV2.Token | undefined;
        if (gas.type === "token") {
          token = gas;
        } else {
          token = gas.tokens.find((token) => token.chainId === asset.chainId);
        }
        if (token === undefined) {
          setErrors((errors) => ({ ...errors, gas: true }));
          // setUserError({ code: GAS_ERR_CODE, message: GAS_ERR_MSG });
          return;
        } else {
          setGasDecimals(token.decimals);
          _gas = { token: token.address, amount: token.rawAmount, scale: "1" };
        }
      }
      try {
        setAmountIn(parseUnits(amount, asset.decimals));
      } catch (e: any) {
        setErrors((errors) => ({ ...errors, amount: true }));
        // setUserError({ code: AMOUNT_ERR_CODE, message: e.toString() });
        return;
      }
      setSourceDecimals(asset.decimals);
      setSources([
        {
          chainId: asset.chainId,
          token: asset.address,
          amount: asset.rawAmount,
          unwrapped: asset.rawNative,
          scale: "1",
          gas: _gas,
        },
      ]);
      setErrors((errors) => ({ ...errors, gas: false, amount: false }));
      // setUserError(undefined);
    } else {
      if (gas.type === "token") {
        setErrors((errors) => ({ ...errors, gas: true }));
        // setUserError({ code: GAS_ERR_CODE, message: GAS_ERR_MSG });
        return;
      }
      let useGas = asset.rawId !== gas.rawId;
      let tokens = asset.tokens.filter((token) =>
        srcNetworks.some((network) =>
          BigNumber.from(network.chainId).eq(token.chainId),
        ),
      );
      // @ts-ignore
      let decimals: number = _(tokens)
        .map((token) => token.decimals)
        .max();
      let gasDecimals = decimals;
      if (useGas) {
        // @ts-ignore
        gasDecimals = _(gas.tokens)
          .map((token) => token.decimals)
          .max();
      }
      setGasDecimals(gasDecimals);
      setSourceDecimals(decimals);
      let sources: transaction.Source[] = [];
      for (let token of tokens) {
        let _gas: transaction.Gas | undefined;
        if (useGas) {
          let _token = gas.tokens.find(
            (_token) => _token.chainId === token.chainId,
          );
          if (_token === undefined) continue;
          _gas = {
            token: _token.address,
            amount: _token.rawAmount,
            scale: BASE_10.pow(gasDecimals - _token.decimals),
          };
        }
        sources.push({
          chainId: token.chainId,
          token: token.address,
          amount: token.rawAmount,
          unwrapped: token.rawNative,
          scale: BASE_10.pow(decimals - token.decimals),
          gas: _gas,
        });
      }

      try {
        setAmountIn(parseUnits(amount, decimals));
      } catch (e: any) {
        setErrors((errors) => ({ ...errors, amount: true }));
        // setUserError({ code: AMOUNT_ERR_CODE, message: e.toString() });
        return;
      }

      setSources(sources);
      setErrors((errors) => ({ ...errors, gas: false, amount: false }));
      // setUserError(undefined);
    }
  }, [amount, asset, gas, srcNetworks]);

  useEffect(() => {
    setDestDecimals(undefined);
    setDestinations(undefined);
    if (coin === undefined || destNetworks === undefined) {
      return;
    }
    let tokens = coin.tokens.filter((token) =>
      destNetworks.some((network) =>
        BigNumber.from(network.chainId).eq(token.chainId),
      ),
    );
    // @ts-ignore
    let decimals: number = _(tokens)
      .map((token) => token.decimals)
      .max();
    setDestDecimals(decimals);
    setDestinations(
      tokens.map((token) => ({
        chainId: token.chainId,
        token: token.address,
        scale: BASE_10.pow(decimals - token.decimals),
      })),
    );
  }, [coin, destNetworks]);

  useEffect(() => {
    console.log({
      // userError,
      errors,
      recipient,
      recipientRe: recipient ? /0x[0-9a-fA-F]{40}/.test(recipient) : false,
      amountIn,
      sender,
      slippage,
      sources,
      destinations,
    });
    if (
      recipient === undefined ||
      !/0x[0-9a-fA-F]{40}/.test(recipient) ||
      Object.values(errors).some((error) => error) ||
      amountIn === undefined ||
      amountIn.isZero() ||
      sender === undefined ||
      slippage === undefined ||
      sources === undefined ||
      destinations === undefined
    ) {
      return;
    }

    let controller = new AbortController();

    setPending(true);
    setResponse(undefined);
    setApiError(undefined);

    TRANSACTION_CLIENT.getRoute(
      {
        amountIn,
        sender,
        recipient,
        slippage,
        sources,
        destinations,
      },
      { signal: controller.signal },
    )
      .then(({ data: response }) => {
        console.log({ response });
        setResponse(response);
      })
      .catch((error) => {
        console.log({ error });
        if (
          error instanceof DOMException &&
          error.code === DOMException.ABORT_ERR
        )
          return;
        setApiError(error);
      })
      .finally(() => setPending(false));

    return () => controller.abort();
  }, [errors, amountIn, sender, recipient, slippage, sources, destinations]);

  const amountOut = useMemo(() => {
    if (response !== undefined && destDecimals !== undefined) {
      return formatUnits(response.amountOut, destDecimals, DECIMALS);
    }
  }, [destDecimals, response]);

  const amountFeeGas = useMemo(() => {
    if (response !== undefined && gasDecimals !== undefined) {
      return formatUnits(response.amountFeeGas, gasDecimals, DECIMALS);
    }
  }, [gasDecimals, response]);

  const amountInSwapped = useMemo(() => {
    if (response !== undefined && sourceDecimals !== undefined) {
      return formatUnits(response.amountInSwapped, sourceDecimals, DECIMALS);
    }
  }, [sourceDecimals, response]);

  const amountInSwappedLess = useMemo(() => {
    if (response !== undefined && amountIn !== undefined) {
      return response.amountInSwapped.lt(amountIn);
    }
  }, [response, amountIn]);

  const price = useMemo(() => {
    if (
      response !== undefined &&
      sourceDecimals !== undefined &&
      destDecimals !== undefined
    ) {
      if (response.amountInSwapped.isZero()) {
        return 0;
      }
      let amountInSwapped = new Decimal(
        response.amountInSwapped.toString(),
      ).div(new Decimal(10).pow(sourceDecimals));
      let amountOut = new Decimal(response.amountOut.toString()).div(
        new Decimal(10).pow(destDecimals),
      );
      console.log({ amountInSwapped, amountOut });
      return amountOut.div(amountInSwapped).toFixed(DECIMALS);
    }
  }, [sourceDecimals, destDecimals, response]);

  const { provider, connector, chainId } = useWeb3React();

  const send = useCallback(async () => {
    if (
      response === undefined ||
      sender === undefined ||
      provider === undefined
    )
      return;
    let outputs = Array.from(response.outputs);
    if (chainId !== undefined) {
      outputs.sort((a) => (a.chainId.eq(chainId) ? -1 : 0));
    }
    let signer = provider.getSigner();
    let params: {
      message: UrbTxMessage;
      nonce: BigNumber;
      chainId: BigNumber;
      signature: SignatureLike;
    }[] = [];
    for (let output of outputs) {
      let {
        chainId,
        transaction: { message, nonce },
      } = output;
      let network = NETWORKS.find((chain) => chainId.eq(chain.chainId));
      if (network === undefined) continue;
      await connector.activate(network?.addEthereumChainParam);
      let signature = await signUrbTxPayload(
        chainId,
        sender,
        message,
        nonce,
        signer,
      );
      params.push({ message, nonce, chainId, signature });
    }
    console.log({ params });
    let items = await Promise.all(
      params.map(async ({ message, nonce, chainId, signature }) => {
        let hash = await RELAY_CLIENT.processTransaction({
          type: "urb-transaction",
          chainId,
          message,
          messageNonce: nonce,
          signature,
          wallet: sender,
          creationNonce: "0x0",
        });
        return { chainId, hash };
      }),
    );
    console.log({ items });
    return items;
  }, [chainId, connector, provider, response, sender]);

  const message = useMemo<Message | undefined>(() => {
    if (errors.amount) {
      return { message: "please provide valid amount", type: "error" };
    } else if (errors.gas || sources?.length === 0) {
      return { message: "please select another gas token", type: "error" };
    } else if (apiError !== undefined) {
      return { message: apiError.message, type: "error" };
    } else if (response?.outputs?.length === 0) {
      return { message: "insuffcieint fee on some chains", type: "warning" };
    }
  }, [apiError, errors, sources, response]);

  return {
    pending,
    response,
    amountOut,
    amountInSwapped,
    price,
    amountFeeGas,
    sourceDecimals,
    amountInSwappedLess,
    send,
    message,
  };
}
