import { CSSProperties, ReactNode } from "react";
import { keyframes, styled } from "styled-components";

const Shimmer = keyframes`
  100% {
    mask-position: left;
    -webkit-mask-position: left;
  }
`;

const Container = styled.div<{ $loading: boolean }>`
  --mask: linear-gradient(90deg, #0001 30%, #000f, #0001 70%) right/300% 100%;
  display: block;
  background-color: ${(props) => props.$loading && "#fff3"};
  mask: ${(props) => props.$loading && "var(--mask)"};
  -webkit-mask: ${(props) => props.$loading && "var(--mask)"};
  animation: ${Shimmer} 1s infinite;
  border-radius: 5px;
`;

const Content = styled.div<{ $loading: boolean }>`
  opacity: ${(props) => props.$loading && "0"};
`;

export type LoadingProps = {
  className?: string;
  style?: CSSProperties;
  children: ReactNode;
  loading: boolean;
};

export function Loading({
  className,
  style,
  children,
  loading,
}: LoadingProps): JSX.Element {
  return (
    <Container className={className} style={style} $loading={loading}>
      <Content $loading={loading}>{children}</Content>
    </Container>
  );
}
