import { CoinbaseWallet } from "@web3-react/coinbase-wallet";
import { Web3ReactHooks, initializeConnector } from "@web3-react/core";
import { MetaMask } from "@web3-react/metamask";
import { Connector } from "@web3-react/types";
import { WalletConnect } from "@web3-react/walletconnect-v2";
import assets from "./assets";

export type Connection = {
  connector: Connector;
  hooks: Web3ReactHooks;
  name: string;
  image: string;
};

export type ConnectionType = "MetaMask" | "WalletConnect" | "Coinbase";

function buildMetaMaskConnection(): Connection {
  let [connector, hooks] = initializeConnector<MetaMask>(
    (actions) => new MetaMask({ actions }),
  );
  (connector as any).__tag = "MetaMask";

  return {
    connector,
    hooks,
    name: "MetaMask",
    image: assets.wallets.MetaMaskLogo,
  };
}

function buildWalletConnectConnection(): Connection {
  const [connector, hooks] = initializeConnector<WalletConnect>(
    (actions) =>
      new WalletConnect({
        actions,
        options: {
          projectId: "20c87449b1ae6fda0f310703fe9fea6e",
          showQrModal: true,
          chains: [1],
          optionalChains: [1, 10, 56, 137, 42161, 43114],
        },
      }),
  );
  (connector as any).__tag = "WalletConnect";

  return {
    connector,
    hooks,
    name: "WalletConnect",
    image: assets.wallets.WalletConnectLogo,
  };
}

function buildCoinbaseConnection(): Connection {
  const [connector, hooks] = initializeConnector<CoinbaseWallet>(
    (actions) =>
      new CoinbaseWallet({
        actions,
        options: {
          appName: "",
          url: "",
        },
      }),
  );
  (connector as any).__tag = "CoinbaseWallet";
  return {
    connector,
    hooks,
    name: "CoinbaseWallet",
    image: assets.wallets.CoinbaseLogo,
  };
}

export const CONNECTIONS: Record<ConnectionType, Connection> = {
  MetaMask: buildMetaMaskConnection(),
  WalletConnect: buildWalletConnectConnection(),
  Coinbase: buildCoinbaseConnection(),
};

export type ConnectorInfo = {
  title: string;
  image: string;
};

export const CONNECTOR_INFO: Record<string, ConnectorInfo> = {
  MetaMask: {
    title: "MetaMask",
    image: assets.wallets.MetaMaskLogo,
  },
  WalletConnect: {
    title: "WalletConnect",
    image: assets.wallets.WalletConnectLogo,
  },
  CoinbaseWallet: {
    title: "Coinbase",
    image: assets.wallets.CoinbaseLogo,
  },
};

export const CONNECTORS = Object.values(CONNECTIONS).map(
  ({ connector, hooks }) => [connector, hooks] as [Connector, Web3ReactHooks],
);
