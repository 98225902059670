import { useCallback, useEffect, useMemo, useState } from "react";
import { styled } from "styled-components";
import assets from "../assets";
import { Search } from "./Dashboard";
import ga from "react-ga";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;
`;

const NetworksContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  max-height: 500px;
  overflow-y: scroll;
`;

const Network = styled.button`
  display: grid;
  grid-template-columns: 30px 1fr 30px;
  column-gap: 15px;
  padding: 10px;
  border-radius: 10px;
  align-items: center;
  outline: none;
  border: none;
  background: none;
  cursor: pointer;
  &:hover {
    background-color: #fff1;
  }
`;

const NetworkLogo = styled.img`
  grid-column: 1;
  width: 100%;
  width: 35px;
  height: 35px;
`;

const NetworkName = styled.p`
  text-align: start;
  grid-column: 2;
  font-family: "Poppins";
  font-weight: 500;
  font-size: 17px;
  color: #fff;
`;

const CheckBox = styled.div`
  grid-column: 3;
  display: block;
  border-radius: 25%;
  width: 15px;
  height: 15px;
  background-color: #478bed;
`;

const Title = styled.h1`
  font-family: "Poppins";
  font-size: 23px;
  font-weight: 500;
  color: #fff;
  text-align: center;
`;

export const BackButton = styled.button`
  position: absolute;
  width: 35px;
  height: 35px;
  background: center / 80% no-repeat url(${assets.BackArrow});
  border: none;
  outline: none;
  padding: 5px;
  border-radius: 10px;
  cursor: pointer;
  &:hover {
    background-color: #fff1;
  }
`;

const SearchNetwork = styled(Search)`
  width: 100%;
`;

// const SelectButton = styled.button`
//   background: none;
//   border: none;
//   outline: none;
//   text-align: start;
//   cursor: pointer;
//   font-family: "Inter", sans-serif;
//   font-weight: 400;
//   font-size: 17px;
//   color: #478bed;
// `;

export type NetworkLike = {
  icon: string;
  name: string;
};

export type SelectNetworkProps = {
  multiple?: boolean;
  networks: readonly NetworkLike[];
  selectedDefault?: Set<number> | number;
  onSelect?: (selected: Set<number> | number) => void;
  onClose?: () => void;
};

function SelectNetwork({
  multiple,
  networks,
  selectedDefault,
  onSelect,
  onClose,
}: SelectNetworkProps): JSX.Element {
  const [selected, setSelected] = useState(
    selectedDefault ?? (multiple === true ? new Set<number>() : -1),
  );
  useEffect(() => {
    ga.modalview("selectNetwork");
    return () =>
      ga.event({
        category: "Swap/Send",
        action: "Closed selectNetwork",
      });
  }, []);
  // useEffect(() => {
  //   onSelect?.(selected);
  // }, [onSelect, selected]);
  const allSelected = useMemo(
    () => typeof selected !== "number" && selected.size === networks.length,
    [selected, networks],
  );
  const selectAll = useCallback(() => {
    let selected = new Set(networks.keys());
    setSelected(selected);
    onSelect?.(selected);
  }, [networks, onSelect]);
  const unselectAll = useCallback(() => {
    let selected = new Set<number>();
    setSelected(selected);
    onSelect?.(selected);
  }, [onSelect]);
  const select = useCallback(
    (index: number) => {
      setSelected((selected) => {
        if (typeof selected === "number") {
          onSelect?.(index);
          return index;
        } else {
          selected = new Set(selected);
          if (selected.has(index)) {
            selected.delete(index);
          } else {
            selected.add(index);
          }
          onSelect?.(selected);
          return selected;
        }
      });
    },
    [onSelect],
  );
  return (
    <Container>
      <BackButton onClick={onClose} />
      <Title>Select network</Title>
      <SearchNetwork placeholder="Search network" />
      <NetworksContainer>
        {multiple && (
          <Network
            onClick={() => {
              if (allSelected) {
                ga.event({
                  category: "Swap/Send",
                  action: "Clicked 'Select all' button",
                  label: "unselect all",
                });
                unselectAll();
              } else {
                ga.event({
                  category: "Swap/Send",
                  action: "Clicked 'Select all' button",
                  label: "select all",
                });
                selectAll();
              }
            }}
          >
            <NetworkLogo src={assets.AllNetworks} alt="all-networks" />
            <NetworkName>All networks</NetworkName>
            {allSelected && <CheckBox />}
          </Network>
        )}
        {networks.map((network, index) => (
          <Network
            key={index}
            onClick={() => {
              select(index);
              ga.event({
                category: "Swap/Send",
                action: "Selected network",
                label: network.name,
              });
            }}
          >
            <NetworkLogo src={network.icon} alt={network.name} />
            <NetworkName>{network.name}</NetworkName>
            {!allSelected &&
              (typeof selected === "number"
                ? selected === index
                : selected.has(index) && <CheckBox />)}
          </Network>
        ))}
      </NetworksContainer>
    </Container>
  );
}

export default SelectNetwork;
