import { AddEthereumChainParameter } from "@web3-react/types";
import assets from "./assets";
import { BigNumberish } from "@ethersproject/bignumber";

export type Network = {
  name: string;
  icon: string;
  chainId: BigNumberish;
  blockExplorerUrl: string;
  addEthereumChainParam: AddEthereumChainParameter;
};

export const NETWORKS: readonly Network[] = [
  {
    name: "Ethereum",
    icon: assets.Eth,
    chainId: "0x1",
    blockExplorerUrl: "https://etherscan.io",
    addEthereumChainParam: {
      chainName: "Mainnet",
      nativeCurrency: {
        name: "Ether",
        symbol: "ETH",
        decimals: 18,
      },
      rpcUrls: ["https://rpc.ankr.com/eth"],
      blockExplorerUrls: ["https://etherscan.io"],
      iconUrls: [
        "https://cryptologos.cc/logos/ethereum-eth-logo.svg",
        "https://cryptologos.cc/logos/ethereum-eth-logo.png",
      ],
      chainId: 1,
    },
  },
  {
    name: "Binance Smart Chain",
    icon: assets.Bnb,
    chainId: "0x38",
    blockExplorerUrl: "https://bscscan.com",
    addEthereumChainParam: {
      chainId: 56,
      chainName: "Binance Smart Chain",
      nativeCurrency: {
        name: "Binance Coin",
        symbol: "BNB",
        decimals: 18,
      },
      rpcUrls: ["https://rpc.ankr.com/bsc"],
      blockExplorerUrls: ["https://bscscan.com"],
      iconUrls: [
        "https://cryptologos.cc/logos/bnb-bnb-logo.svg",
        "https://cryptologos.cc/logos/bnb-bnb-logo.png",
      ],
    },
  },
  {
    name: "Polygon",
    icon: assets.Polygon,
    chainId: "0x89",
    blockExplorerUrl: "https://polygonscan.com",
    addEthereumChainParam: {
      chainId: 137,
      chainName: "Polygon",
      nativeCurrency: {
        name: "Matic",
        symbol: "MATIC",
        decimals: 18,
      },
      rpcUrls: ["https://rpc.ankr.com/polygon"],
      blockExplorerUrls: ["https://polygonscan.com"],
      iconUrls: [
        "https://cryptologos.cc/logos/polygon-matic-logo.svg",
        "https://cryptologos.cc/logos/polygon-matic-logo.png",
      ],
    },
  },
  {
    name: "Avalanche",
    icon: assets.Avalanche,
    chainId: "0xa86a",
    // blockExplorerUrl: "https://snowtrace.io",
    blockExplorerUrl: "https://avascan.info/blockchain/c",
    addEthereumChainParam: {
      chainId: 43114,
      chainName: "Avalanche C-Chain",
      nativeCurrency: {
        name: "Avax",
        symbol: "AVAX",
        decimals: 18,
      },
      rpcUrls: ["https://rpc.ankr.com/avalanche"],
      // blockExplorerUrls: ["https://snowtrace.io"],
      blockExplorerUrls: ["https://avascan.info/blockchain/c"],
      iconUrls: [
        "https://cryptologos.cc/logos/avalanche-avax-logo.svg",
        "https://cryptologos.cc/logos/avalanche-avax-logo.png",
      ],
    },
  },
  {
    name: "Arbitrum",
    icon: assets.Arbitrum,
    chainId: "0xa4b1",
    blockExplorerUrl: "https://arbiscan.io",
    addEthereumChainParam: {
      chainId: 42161,
      chainName: "Arbitrum One",
      nativeCurrency: {
        name: "Ether",
        symbol: "ETH",
        decimals: 18,
      },
      rpcUrls: ["https://rpc.ankr.com/arbitrum"],
      blockExplorerUrls: ["https://arbiscan.io"],
      iconUrls: [
        "https://cryptologos.cc/logos/ethereum-eth-logo.svg",
        "https://cryptologos.cc/logos/ethereum-eth-logo.png",
      ],
    },
  },
  {
    name: "Optimism",
    icon: assets.Optimism,
    chainId: "0xa",
    blockExplorerUrl: "https://optimistic.etherscan.io",
    addEthereumChainParam: {
      chainId: 10,
      chainName: "Optimistic Ethereum",
      nativeCurrency: {
        name: "Ether",
        symbol: "ETH",
        decimals: 18,
      },
      rpcUrls: ["https://rpc.ankr.com/optimism"],
      blockExplorerUrls: ["https://optimistic.etherscan.io"],
      iconUrls: [
        "https://cryptologos.cc/logos/optimism-ethereum-op-logo.png",
        "https://cryptologos.cc/logos/optimism-ethereum-op-logo.svg",
      ],
    },
  },
];

export const EIP155_CHAINS: string[] = [
  "eip155:1",
  "eip155:10",
  "eip155:56",
  "eip155:137",
  "eip155:42161",
  "eip155:43114",
];
