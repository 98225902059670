import Fuse from "fuse.js";
import { useMemo } from "react";
import { styled } from "styled-components";
import appAssets from "../assets";
import ga from "react-ga";
import { apiV2 } from "@uroboros-labs/wallet-sdk";

const Table = styled.div`
  width: 100%;
  /* display: grid;
  grid-template-columns: auto 1fr repeat(3, 120px); */
  display: flex;
  flex-direction: column;
  row-gap: 15px;
  align-items: stretch;
`;

const ControlsRow = styled.div`
  display: grid;
  grid-template-columns: 120px 1fr repeat(3, 120px);
  /* width: 100%; */
  padding-bottom: 15px;
  border-bottom: 1px solid #2d2d2d;
  align-items: center;
`;

const AssetRow = styled.div`
  display: grid;
  grid-template-columns: auto 1fr repeat(3, 120px);
  width: 100%;
  align-items: center;
  padding-bottom: 15px;
  border-bottom: 1px solid #2d2d2d;
`;

const Label = styled.button`
  color: #fff;
  font-size: 17px;
  font-family: "Poppins";
  font-weight: 500;
  text-align: center;
  background-color: transparent;
  outline: none;
  border: none;
  cursor: pointer;
  &::after {
    position: relative;
    top: 6px;
    left: 5px;
    content: "";
    display: inline-block;
    width: 12px;
    height: 21px;
    background: url(${appAssets.buttons.Scroll}) no-repeat center / 100%;
  }
`;

const Title = styled.p`
  text-align: center;
  font-family: "Poppins";
  font-weight: 600;
  font-size: 17px;
`;

const AssetInfoContainer = styled.div`
  display: grid;
  column-gap: 20px;
  grid-template-columns: 50px auto;
  grid-template-rows: 25px 25px;
  align-items: center;
`;

const AssetImage = styled.img`
  grid-column: 1;
  grid-row: 1 / span 2;
  width: 50px;
  height: 50px;
`;

const AssetSymbol = styled.p`
  grid-column: 2;
  grid-row: 1;
  font-family: "Poppins";
  font-weight: 700;
  font-size: 20px;
  white-space: nowrap;
  max-width: 400px;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const AssetName = styled.p`
  grid-column: 2;
  grid-row: 2;
  font-family: "Poppins";
  color: #aaa;
  font-weight: 500;
  font-size: 17px;
`;

const AssetPrice = styled(Title)`
  grid-column: 3;
`;

const AssetAmount = styled(Title)`
  grid-column: 4;
`;

const AssetValue = styled(Title)`
  grid-column: 5;
`;

export type AssetLike = {
  name: string;
  symbol: string;
  image?: string;
  amount: number;
  price?: number;
};

export type AssetsProps = {
  // assets: AssetLike[];
  assets: apiV2.AssetOrToken[];
  search?: string;
};

const DECIMALS: number = 5;

function Assets({ assets, search }: AssetsProps): JSX.Element {
  const fuse = useMemo(
    () => new Fuse(assets, { keys: ["symbol", "name"] }),
    [assets],
  );
  const fusedAssets = useMemo(() => {
    if (search !== undefined && search !== "") {
      return fuse.search(search).map(({ item }) => item);
    }
  }, [fuse, search]);
  return (
    <Table>
      <ControlsRow
        onClick={() =>
          ga.event({
            category: "ACcount",
            action: "Clicked asset labels",
          })
        }
      >
        <Label style={{ gridColumn: 1 }}>Asset</Label>
        <Label style={{ gridColumn: 3 }}>Price</Label>
        <Label style={{ gridColumn: 4 }}>Amount</Label>
        <Label style={{ gridColumn: 5 }}>Value</Label>
      </ControlsRow>
      {(fusedAssets ?? assets).map((asset, index) => (
        <AssetRow key={index}>
          <AssetInfoContainer style={{ gridColumn: 1 }}>
            <AssetImage
              src={asset.type === "asset" ? asset.image : appAssets.Disabled}
              alt={asset.name}
              loading="lazy"
            />
            <AssetSymbol>{asset.symbol.toUpperCase()}</AssetSymbol>
            <AssetName>{asset.name}</AssetName>
          </AssetInfoContainer>
          <AssetPrice>
            {asset.type === "asset"
              ? `$${asset.price.toFixed(DECIMALS)}`
              : "N/A"}
          </AssetPrice>
          <AssetAmount>{asset.amount.toFixed(DECIMALS)}</AssetAmount>
          <AssetValue>
            {asset.type === "asset"
              ? `$${(asset.amount * asset.price).toFixed(DECIMALS)}`
              : "N/A"}
          </AssetValue>
        </AssetRow>
      ))}
    </Table>
  );
}

export default Assets;
