import { Account, apiV2 } from "@uroboros-labs/wallet-sdk";
import { Fragment, useMemo, useState } from "react";
import { PieChart } from "react-minimal-pie-chart";
import { useOutletContext } from "react-router-dom";
import { styled } from "styled-components";
import assets from "../assets";
import { InterfaceOutletContext } from "../pages/Interface";
import Assets from "./Assets";
import { Loading } from "./Loading";
import Switch from "./Switch";
import ga from "react-ga";

const Content = styled.div`
  display: grid;
  grid-template-columns: 200px 200px 1fr 200px 200px;
  grid-template-rows: 100px 150px 100px 1fr;
  column-gap: 50px;
`;

const ContentControlsContainer = styled.div`
  grid-row: 3;
  grid-column: 1 / -1;
  display: flex;
  align-items: center;
  gap: 30px;
`;

export const Search = styled.input`
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  --image-padding: 44px;
  width: calc(100% - var(--image-padding));
  height: 51px;
  width: 400px;
  background: url(${assets.buttons.Search}) no-repeat 12px / 20px;
  border: 2px solid #404040;
  color: #404040;
  border-radius: 10px;
  font-family: "Poppins";
  font-weight: 500;
  font-size: 20px;
  outline: none;
  padding-left: var(--image-padding);
  &::placeholder {
    color: #404040;
  }
  &::selection {
    background-color: #fff1;
  }
`;

const AssetsContainer = styled.div`
  padding: 50px 0;
  grid-column: 1 / -1;
  grid-row: 4;
`;

const PortfolioTitle = styled.h1`
  grid-row: 1;
  grid-column: 1;
  font-family: "Poppins";
  font-weight: 600;
`;

const PortfolioSubtitle = styled.h2`
  font-family: "Poppins";
  font-weight: 400;
  font-size: 25px;
`;

const PortfolioValue = styled(PortfolioSubtitle)`
  padding-top: 10px;
  font-weight: 600;
  font-size: 30px;
`;

const PortfolioChangeValue = styled(PortfolioValue)<{ $green: boolean }>`
  color: ${(props) => (props.$green ? "#00ff94" : "red")};
`;

const PortfolioBalanceContainer = styled.div`
  grid-column: 1;
  grid-row: 2;
`;

const PortfolioChangeContainer = styled(PortfolioBalanceContainer)`
  grid-column: 2;
`;

const PieChartLabelContainer = styled.div`
  /* grid-column: 5;
  grid-row: 1 / span 2; */
  display: grid;
  grid-template-columns: 30px 1fr 50px;
  row-gap: 15px;
`;

const PieChartLabelColor = styled.div<{ $color: string }>`
  grid-column: 1;
  display: block;
  background-color: ${(props) => props.$color};
  width: 20px;
  height: 20px;
  border-radius: 5px;
`;

const PieChartName = styled.p`
  grid-column: 2;
`;

const PieChartPercent = styled.p`
  grid-column: 3;
  text-align: end;
`;

const colors = ["#c028ec", "#faa916", "#344966", "#0d1821", "#2dfaef"];

type DataEntry = {
  value: number;
  name: string;
  color: string;
};

function getChartData(assets: apiV2.AssetOrToken[]): DataEntry[] {
  let entries: DataEntry[] = [];
  let totalValue = 0;
  for (const asset of assets) {
    if (asset.type === "asset") {
      totalValue += asset.value;
    }
  }
  let count = 0;
  let percents = 0;
  for (const asset of assets) {
    if (asset.type === "asset") {
      let valueRatioPercentage = Math.floor((asset.value / totalValue) * 100);
      let color = colors[count];
      entries.push({
        value: valueRatioPercentage,
        name: asset.name,
        color,
      });
      percents += valueRatioPercentage;
      count += 1;
      if (count === colors.length - 1) {
        break;
      }
    }
  }
  entries.push({
    value: 100 - percents,
    name: "Other",
    color: colors[count],
  });
  return entries;
}

// const mock: AssetLike[] = new Array(10).fill({
//   amount: 101,
//   name: "Tether",
//   symbol: "USDT",
//   price: 1.01,
//   image: assets.tokens.Tether,
// });

const DECIMALS: number = 5;

function Dashboard(): JSX.Element {
  const { accountV2: account, assets } =
    useOutletContext<InterfaceOutletContext>();
  const chartData = useMemo(
    () => (assets !== undefined ? getChartData(assets) : undefined),
    [assets],
  );
  const [search, setSearch] = useState("");

  return (
    <Content>
      <PortfolioTitle>My portfolio</PortfolioTitle>
      <PortfolioBalanceContainer>
        <PortfolioSubtitle>Balance</PortfolioSubtitle>
        <PortfolioValue>
          <Loading loading={account === undefined}>
            {account?.value?.toFixed(DECIMALS) ?? "none"}
          </Loading>
        </PortfolioValue>
      </PortfolioBalanceContainer>
      <PortfolioChangeContainer>
        <PortfolioSubtitle>24h change</PortfolioSubtitle>
        <PortfolioChangeValue $green={(account?.valueChange24h ?? 0) > 0}>
          <Loading loading={account === undefined}>
            {/* {(account?.valueChange24h ?? 0) > 0 ? "+" : "-"} */}
            {account?.valueChange24h?.toFixed(DECIMALS) ?? "none"}
          </Loading>
        </PortfolioChangeValue>
      </PortfolioChangeContainer>
      <Loading
        loading={account === undefined}
        style={{ gridColumn: 4, gridRow: "1 / span 2", height: "200px" }}
      >
        <PieChart data={chartData ?? []} lineWidth={25} startAngle={-90} />
      </Loading>
      <Loading
        loading={account === undefined}
        style={{ gridColumn: 5, gridRow: "1 / span 2" }}
      >
        <PieChartLabelContainer>
          {chartData?.map((entry, key) => (
            <Fragment key={key}>
              <PieChartLabelColor $color={entry.color} />
              <PieChartName>{entry.name}</PieChartName>
              <PieChartPercent>{entry.value}%</PieChartPercent>
            </Fragment>
          )) ?? (
            <>
              {/* TODO: div placeholder */}
              <PieChartLabelColor $color="#c028ec" />
              <PieChartName>Ethereum</PieChartName>
              <PieChartPercent>50%</PieChartPercent>
              <PieChartLabelColor $color="#faa916" />
              <PieChartName>Tether</PieChartName>
              <PieChartPercent>40%</PieChartPercent>
              <PieChartLabelColor $color="#344966" />
              <PieChartName>Binance Coin</PieChartName>
              <PieChartPercent>30%</PieChartPercent>
              <PieChartLabelColor $color="#0d1821" />
              <PieChartName>Cardano</PieChartName>
              <PieChartPercent>20%</PieChartPercent>
              <PieChartLabelColor $color="#2dfaef" />
              <PieChartName>Other</PieChartName>
              <PieChartPercent>10%</PieChartPercent>
            </>
          )}
        </PieChartLabelContainer>
      </Loading>
      <ContentControlsContainer>
        <Switch
          options={["Coins", "NFTs"]}
          onSelect={(option) => {
            ga.event({
              category: "Account",
              action: "Switched Coins/NFTs",
              label: option,
            });
          }}
        />
        <Search
          placeholder="Search coin, NFT, etc."
          onChange={(e) => setSearch(e.target.value)}
          onFocus={() =>
            ga.event({
              category: "Account",
              action: "Entered search field",
            })
          }
        />
      </ContentControlsContainer>
      <AssetsContainer>
        <Assets assets={assets ?? []} search={search} />
      </AssetsContainer>
    </Content>
  );
}

export default Dashboard;
