import { BigNumber, BigNumberish } from "@ethersproject/bignumber";

const BASE_10 = BigNumber.from(10);

export function formatUnits(
  amount: BigNumberish,
  unitName: number,
  decimalPlaces: number,
): string {
  amount = BigNumber.from(amount);
  let exp10 = BASE_10.pow(unitName);
  let integer = amount.div(exp10).toString();
  let fraction = amount.mod(exp10).toString().slice(0, decimalPlaces);
  return `${integer}.${fraction}`;
}
