import { useState } from "react";
import { styled } from "styled-components";

const Container = styled.div`
  display: flex;
  align-items: center;
  background-color: transparent;
  padding: 3px;
  border-radius: 10px;
  gap: 3px;
  border: 2px solid #404040;
`;

const Option = styled.button<{ $selected: boolean }>`
  background-color: ${(props) => (props.$selected ? "#1b1b1b" : "transparent")};
  color: #404040;
  border: none;
  outline: none;
  height: 45px;
  min-width: 120px;
  border-radius: 5px;
  font-size: 20px;
  font-weight: 500;
  font-family: "Poppins";
  cursor: pointer;

  &:hover {
    background-color: #fff1;
  }
`;

export type SwitchProps = {
  options: string[];
  onSelect?: (option: string, index: number) => void;
};

function Switch({ options, onSelect }: SwitchProps): JSX.Element {
  const [selected, setSelected] = useState(0);
  return (
    <Container>
      {options.map((option, index) => (
        <Option
          key={index}
          onClick={() => {
            setSelected(index);
            onSelect?.(option, index);
          }}
          $selected={selected === index}
        >
          {option}
        </Option>
      ))}
    </Container>
  );
}

export default Switch;
