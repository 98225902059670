import { BigNumberish } from "@ethersproject/bignumber";
import { apiV2 } from "@uroboros-labs/wallet-sdk";
import { useContext, useEffect, useMemo, useState } from "react";
import ga from "react-ga";
import { keyframes, styled } from "styled-components";
import { useDebounce } from "usehooks-ts";
import { ApiContext } from "../context";
import useRoute from "../hooks/useRoute";
import { NETWORKS } from "../networks";
import GasSelect from "./GasSelect";
import MultiSelect from "./MultiSelect";
import NetworkAddressSelect from "./NetworkAddressSelect";
import SelectAsset from "./SelectAsset";
import SelectNetwork from "./SelectNetwork";
import {
  ErrorText,
  RouteStats,
  Stat,
  StatKey,
  StatRow,
  State,
  WarningText,
} from "./SwapModal";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 25px;
  gap: 10px;
  border-radius: 30px;
  min-height: 300px;
  max-height: 700px;
  overflow-y: scroll;
  min-width: 450px;
  background-color: #151515;
  border: 2px solid #404040;
  user-select: none;
`;

const Title = styled.h1`
  font-family: "Poppins";
  font-weight: 500;
  font-size: 23px;
  color: #fff;
  align-self: center;
`;

const Shimmer = keyframes`
  100% {
    mask-position: left;
    -webkit-mask-position: left;
  }
`;

export const Button = styled.button<{ $pending: boolean }>`
  --mask: ${(props) =>
    props.$pending
      ? "linear-gradient(90deg, #000a 30%, #000f, #000a 70%) right/300% 100%"
      : "none"};
  mask: var(--mask);
  -webkit-mask: var(--mask);
  animation: ${Shimmer} 500ms infinite;
  height: 60px;
  width: 100%;
  font-family: "Poppins";
  font-weight: 500;
  font-size: 17px;
  background-color: #2dfaef;
  border: none;
  outline: none;
  border-radius: 15px;
  cursor: pointer;
  &:hover {
    opacity: 0.9;
  }
`;

type SendModalProps = {
  account?: string;
  assets: apiV2.AssetOrToken[];
  onSend?: (tx: { chainId: BigNumberish; hash: string }[]) => void;
};

const ALL_NETWORKS = new Set(NETWORKS.keys());

function SendModal({ account, assets, onSend }: SendModalProps): JSX.Element {
  useEffect(() => {
    ga.modalview("send");
    return () =>
      ga.event({
        category: "Swap/Send",
        action: "Closed send",
      });
  }, []);
  const [state, setState] = useState<State | undefined>();
  const [asset, setAsset] = useState<apiV2.AssetOrToken | undefined>(
    () => assets?.[0],
  );
  const [gasAsset, setGasAsset] = useState<apiV2.AssetOrToken | undefined>(
    () => assets?.find((asset) => (asset as any).isGas), // TODO: asset.isGas
  );
  const [value, setValue] = useState<number>(0);
  const debouncedValue = useDebounce(value, 300);
  const [sourceNetworks, setSourceNetworks] = useState(ALL_NETWORKS);
  const [destNetwork, setDestNetwork] = useState<number>();
  const [destAddress, setDestAddress] = useState("");
  const { coins } = useContext(ApiContext);
  const coin = useMemo(() => {
    if (coins !== undefined && asset !== undefined && asset.type === "asset") {
      // return coins.find((coin) => Object.is(coin, asset));
      return coins.find((coin) => coin.rawId === asset.rawId);
    }
  }, [coins, asset]);
  const _srcNetworks = useMemo(() => {
    return sourceNetworks !== undefined
      ? Array.from(sourceNetworks).map((network) => NETWORKS[network])
      : undefined;
  }, [sourceNetworks]);
  // chainId == undefined probably here
  const destNetworks = useMemo(
    () => (destNetwork !== undefined ? [NETWORKS[destNetwork]] : undefined),
    [destNetwork],
  );

  const {
    amountOut,
    amountFeeGas,
    amountInSwapped,
    price,
    response,
    message,
    send,
    amountInSwappedLess,
  } = useRoute({
    gas: gasAsset,
    asset: asset,
    coin,
    amount: debouncedValue.toString(),
    destNetworks,
    srcNetworks: _srcNetworks,
    recipient: destAddress,
    slippage: 1,
    sender: account,
  });
  useEffect(() => console.log({ response, message }), [response, message]);
  const [pending, setPending] = useState(false);
  const content = useMemo(() => {
    switch (state?.type) {
      case "select/network":
        return (
          <SelectNetwork
            multiple={state.multiple}
            selectedDefault={state.selectedDefault}
            networks={NETWORKS}
            onSelect={state.onSelect}
            onClose={() => setState(undefined)}
          />
        );
      case "select/asset":
        return (
          <SelectAsset
            assets={state.assets}
            onSelect={(asset) => {
              state.onSelect(asset);
              setState(undefined);
            }}
            onClose={() => setState(undefined)}
            isWarning={state.isWarning}
          />
        );
      default:
        return (
          <>
            <Title>Send</Title>
            <MultiSelect
              asset={asset}
              network={Array.from(sourceNetworks).map(
                (index) => NETWORKS[index],
              )}
              defaultValue={value}
              onValueChange={setValue}
              onNetworkClick={() =>
                setState({
                  type: "select/network",
                  selectedDefault: sourceNetworks,
                  onSelect: (selected) => {
                    if (typeof selected !== "number") {
                      setSourceNetworks(selected);
                    }
                  },
                  multiple: true,
                })
              }
              onAssetClick={() =>
                setState({
                  type: "select/asset",
                  // @ts-ignore
                  onSelect: setAsset,
                  assets,
                })
              }
            />
            <NetworkAddressSelect
              network={
                destNetwork !== undefined ? NETWORKS[destNetwork] : undefined
              }
              defaultAddress={destAddress}
              onAddressChange={setDestAddress}
              onNetworkClick={() =>
                setState({
                  type: "select/network",
                  selectedDefault: destNetwork,
                  onSelect: (selected) => {
                    if (typeof selected === "number") {
                      setDestNetwork(selected);
                      setState(undefined);
                    }
                  },
                })
              }
            />
            <GasSelect
              // amount={gasFee?.toFixed(5)}
              amount={amountFeeGas}
              selected={gasAsset}
              onSelectClick={() =>
                setState({
                  type: "select/asset",
                  // @ts-ignore
                  onSelect: setGasAsset,
                  assets: assets.filter(
                    (asset) => asset.gas !== null && asset.gas > 0,
                  ),
                  isWarning: (asset) => (asset as apiV2.AssetOrToken).gas === 1,
                })
              }
            />
            <RouteStats>
              {amountInSwappedLess && (
                <StatRow>
                  <StatKey $warning>Max swappable amount:</StatKey>
                  <Stat $warning>{amountInSwapped ?? "0.0"}</Stat>
                </StatRow>
              )}
              <StatRow>
                <StatKey>You receive:</StatKey>
                <Stat>{amountOut ?? "0.0"}</Stat>
              </StatRow>
            </RouteStats>
            {message &&
              (message.type === "error" ? (
                <ErrorText>{message.message}</ErrorText>
              ) : (
                <WarningText>{message.message}</WarningText>
              ))}
            <Button
              $pending={pending}
              onClick={async () => {
                ga.event({
                  category: "Swap/Send",
                  action: "Clicked 'Send' button",
                });
                setPending(true);
                try {
                  let tx = await send();
                  if (tx !== undefined && tx.length > 0) {
                    onSend?.(tx);
                  }
                } finally {
                  setPending(false);
                }
              }}
            >
              Send
            </Button>
          </>
        );
    }
  }, [
    amountFeeGas,
    amountInSwapped,
    amountInSwappedLess,
    amountOut,
    asset,
    assets,
    destAddress,
    destNetwork,
    gasAsset,
    message,
    onSend,
    pending,
    price,
    send,
    sourceNetworks,
    state,
    value,
  ]);
  return <Container>{content}</Container>;
}

export default SendModal;
