import { useWeb3React } from "@web3-react/core";
import BlockiesSvg from "blockies-react-svg";
import { styled } from "styled-components";
import assets from "../assets";
import { useNavigate } from "react-router-dom";
import ga from "react-ga";
import { useEffect } from "react";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: center;
  width: 200px;
  background-color: #151515;
  border: 2px solid #373737;
  border-radius: 5px;
  padding: 15px;
`;

const Title = styled.h1`
  font-family: "Poppins";
  font-weight: 400;
  font-size: 17px;
  color: #fff;
`;

const ConnectButton = styled.button`
  background-color: #2dfaef;
  color: #000;
  border: none;
  border-radius: 5px;
  font-family: "Poppins";
  font-weight: 500;
  font-size: 17px;
  width: 100%;
  height: 40px;
  cursor: pointer;
  outline: none;
  &:hover {
    opacity: 0.9;
  }
`;

const DisconnectButton = styled(ConnectButton)`
  background-color: #fa392d;
`;

const Avatar = styled(BlockiesSvg)`
  border-radius: 50%;
  width: 50px;
  height: 50px;
`;

const Row = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 30px;
  border-bottom: 2px solid #434343;
  color: #b9b9b9;
  font-family: "Poppins";
`;

function ConnectionModal(): JSX.Element {
  useEffect(() => {
    ga.modalview("connection");
    return () =>
      ga.event({
        category: "Account",
        action: "Closed connection",
      });
  }, []);

  const { account, connector, chainId } = useWeb3React();
  const navigate = useNavigate();

  if (!account) {
    return (
      <Container>
        <Title>Connect a wallet</Title>
        <img src={assets.BlankWallet} alt="blank-wallet" />
        <ConnectButton
          onClick={() => {
            navigate("/creation");
            ga.event({
              category: "Account",
              action: "Clicked connect button",
            });
          }}
        >
          Connect
        </ConnectButton>
      </Container>
    );
  }

  return (
    <Container>
      <Avatar address={account} />
      <Row>
        <p>Wallet</p>
        <p>{(connector as any).__tag}</p>
      </Row>
      <Row>
        <p>Chain</p>
        <p>{chainId}</p>
      </Row>
      <DisconnectButton
        onClick={async () => {
          ga.event({
            category: "Account",
            action: "Clicked disconnect button",
          });
          await connector.deactivate?.();
          await connector.resetState();
        }}
      >
        Disconnect
      </DisconnectButton>
    </Container>
  );
}

export default ConnectionModal;
