import { useState } from "react";
import { styled } from "styled-components";
import assets from "../assets";
import { AssetLike } from "./SelectAsset";
import { NetworkLike } from "./SelectNetwork";
import { NETWORKS } from "../networks";
import ga from "react-ga";

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  border: 2px solid #404040;
  border-radius: 15px;
`;

const NetworkButtonContainer = styled.div`
  padding: 10px;
  border-bottom: 2px solid #404040;
`;

const BaseButton = styled.button`
  background: none;
  border: none;
  outline: none;
  cursor: pointer;
  padding: 5px;
  border-radius: 10px;
  &:hover {
    background-color: #fff1;
  }
`;

const NetworkButton = styled(BaseButton)`
  border-radius: 10px;
  display: flex;
  align-items: center;
  gap: 10px;
`;

const NetworkLogo = styled.img`
  width: 25px;
  height: 25px;
  margin-left: -20px;
  &:first-child {
    margin-left: 0;
  }
`;

const NetworkName = styled.p`
  font-family: "Poppins";
  font-weight: 500;
  font-size: 17px;
  color: #fff;
`;

const ArrowDown = styled.img``;

const AssetInputContainer = styled.div`
  padding: 10px;
  display: grid;
  align-items: center;
  grid-template-rows: 30px 60px 30px;
  grid-template-columns: 1fr auto;
`;

const AssetInputText = styled.p`
  font-family: "Poppins";
  font-weight: 400;
  font-size: 17px;
  color: #6f6f6f;
`;

const AssetInputUpperText = styled(AssetInputText)`
  grid-row: 1;
  grid-column: 1;
`;

const AssetInputLowerText = styled(AssetInputText)`
  grid-row: 3;
  grid-column: 1;
  cursor: pointer;
`;

const AssetButtonContainer = styled.div`
  grid-row: 1 / -1;
  grid-column: 2;
  display: flex;
  justify-content: flex-end;
`;

const AssetButton = styled(BaseButton)`
  display: flex;
  align-items: center;
  gap: 10px;
`;

const AssetSymbol = styled.p`
  font-family: "Inter";
  font-weight: 400;
  font-size: 23px;
  color: #fcfcff;
`;

const AssetLogo = styled.img`
  width: 35px;
  height: 35px;
`;

const ValueInput = styled.input`
  --padding: 10px;
  grid-row: 2;
  grid-column: 1;
  height: calc(100% - var(--padding) * 2);
  width: 100%;
  padding: var(--padding) 0;
  border: none;
  outline: none;
  background: none;
  font-family: "Inter";
  font-weight: 500;
  font-size: 30px;
  color: #acacac;
  appearance: textfield;
  -moz-appearance: textfield;
  &::-webkit-inner-spin-button,
  &::-webkit-outer-spin-button {
    -webkit-appearance: none;
  }
`;

export type MultiSelectProps = {
  asset?: AssetLike;
  network?: NetworkLike | NetworkLike[];
  defaultValue?: number;
  fixedValue?: number | string;
  balance?: number;
  type?: "send" | "receive";
  onAssetClick?: () => void;
  onNetworkClick?: () => void;
  onValueChange?: (value: number) => void;
};

function MultiSelect({
  asset,
  network,
  defaultValue,
  fixedValue,
  balance,
  type,
  onAssetClick,
  onNetworkClick,
  onValueChange,
}: MultiSelectProps): JSX.Element {
  const [value, setValue] = useState(defaultValue ?? 0.0);
  return (
    <Container>
      <NetworkButtonContainer>
        <NetworkButton onClick={onNetworkClick}>
          {network === undefined ||
          (Array.isArray(network) && network.length === 0) ? (
            <NetworkName>Select</NetworkName>
          ) : Array.isArray(network) && network.length === NETWORKS.length ? (
            <>
              <NetworkLogo src={assets.AllNetworks} alt={"all-networks"} />
              <NetworkName>All networks</NetworkName>
            </>
          ) : Array.isArray(network) && network.length === 1 ? (
            <>
              <NetworkLogo src={network[0].icon} alt={network[0].name} />
              <NetworkName>{network[0].name}</NetworkName>
            </>
          ) : Array.isArray(network) ? (
            <>
              {network?.map((network, index) => (
                <NetworkLogo
                  key={index}
                  src={network.icon}
                  alt={network.name}
                />
              ))}
              <NetworkName>Multiple</NetworkName>
            </>
          ) : (
            <>
              <NetworkLogo src={network.icon} alt={network.name} />
              <NetworkName>{network.name}</NetworkName>
            </>
          )}
          <ArrowDown src={assets.buttons.ArrowDown} alt="arrow-down" />
        </NetworkButton>
      </NetworkButtonContainer>
      <AssetInputContainer>
        <AssetInputUpperText>
          {type === "receive" ? "You receive" : "You send"}
        </AssetInputUpperText>
        <ValueInput
          type="number"
          min="0"
          placeholder="0,0"
          value={fixedValue ?? value}
          disabled={fixedValue !== undefined}
          onChange={(e) => {
            let value = Number.parseFloat(e.target.value);
            setValue(value);
            onValueChange?.(value);
          }}
          onKeyDown={(e) => {
            if (e.key === "-" || e.key === "+") e.preventDefault();
          }}
        />
        <AssetInputLowerText
          onClick={() => {
            ga.event({
              category: "Swap/Send",
              action: "Clicked balance",
            });
            if (asset?.amount !== undefined) {
              setValue(asset.amount);
              onValueChange?.(asset.amount);
            }
          }}
        >
          Balance: {asset?.amount ?? balance ?? "N/A"}
        </AssetInputLowerText>
        <AssetButtonContainer>
          <AssetButton onClick={onAssetClick}>
            <AssetLogo src={asset?.image ?? assets.Disabled} alt="" />
            <AssetSymbol>
              {asset?.symbol?.toUpperCase() ?? "Select"}
            </AssetSymbol>
            <img src={assets.buttons.ArrowDown} alt="" />
          </AssetButton>
        </AssetButtonContainer>
      </AssetInputContainer>
    </Container>
  );
}

export default MultiSelect;
