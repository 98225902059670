import { BigNumber } from "@ethersproject/bignumber";
import { formatUnits } from "@ethersproject/units";
import { Erc20TransferEvent } from "@uroboros-labs/wallet-sdk";
import { useContext } from "react";
import { useOutletContext } from "react-router-dom";
import { styled } from "styled-components";
import assets from "../assets";
import { ApiContext } from "../context";
import { NETWORKS } from "../networks";
import { InterfaceOutletContext } from "./Interface";

const TransactionsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 40px;
`;

const Transaction = styled.div`
  display: block;
  background-color: #151515;
  padding: 15px;
  row-gap: 10px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
`;

const TableRow = styled.tr`
  height: 30px;
`;

const ColumnTitle = styled.td`
  font-family: Inter;
  font-size: 15px;
  font-weight: 500;
  color: #dadada;
`;

export function formatHash(address: string): string {
  return address.replace(/(0x.{4}).*(.{4})/, "$1...$2");
}

function Transactions(): JSX.Element {
  const { transactions } = useOutletContext<InterfaceOutletContext>();
  const { coins } = useContext(ApiContext);

  return (
    <>
      <TransactionsContainer>
        {transactions?.map((transaction, key) => {
          let network = NETWORKS.find((network) =>
            BigNumber.from(transaction.chainId).eq(network.chainId),
          );
          return (
            <Transaction key={key}>
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <div>
                  {/* <h1
                    style={{
                      color: "#a3a3a3",
                      fontSize: "20px",
                      fontWeight: 500,
                    }}
                  >
                    N/A
                  </h1> */}
                  <h2
                    style={{
                      color: "#e3e3e3",
                      fontSize: "15px",
                      fontFamily: "Inter",
                      fontWeight: 500,
                    }}
                  >
                    Transaction hash:{" "}
                    <a
                      style={{
                        textDecoration: "none",
                        color: "#e3e3e3",
                        fontFamily: "JetBrains Mono",
                        fontWeight: 400,
                      }}
                      target="_blank"
                      rel="noreferrer"
                      href={`${
                        network?.blockExplorerUrl ?? "https://etherscan.io"
                      }/tx/${transaction.transactionHash}`}
                    >
                      {formatHash(transaction.transactionHash)}
                    </a>
                  </h2>
                  <h2
                    style={{
                      color: "#e3e3e3",
                      fontSize: "15px",
                      fontFamily: "Inter",
                      fontWeight: 500,
                    }}
                  >
                    Tokens transferred (
                    {
                      transaction.logs.filter(
                        (log) => log.event.type === "erc20-transfer",
                      ).length
                    }
                    ):
                  </h2>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "10px",
                    alignItems: "flex-end",
                  }}
                >
                  <img
                    title={network?.name}
                    style={{ height: "30px" }}
                    src={network?.icon}
                    alt="blockchain"
                  />
                  <h2
                    style={{
                      color: "#5ED07A",
                      fontSize: "15px",
                      fontWeight: 500,
                      fontFamily: "Inter",
                    }}
                  >
                    Success
                  </h2>
                </div>
              </div>
              <hr
                style={{
                  width: "100%",
                  height: "1px",
                  border: "none",
                  backgroundColor: "#3a3a3a",
                  margin: 0,
                  padding: 0,
                }}
              />
              <table style={{ width: "80%", tableLayout: "fixed" }}>
                <TableRow>
                  <ColumnTitle>From</ColumnTitle>
                  <ColumnTitle>To</ColumnTitle>
                  <ColumnTitle>Amount</ColumnTitle>
                  <ColumnTitle>Token</ColumnTitle>
                </TableRow>
                {transaction.logs
                  .filter((log) => log.event.type === "erc20-transfer")
                  .map((log, key) => {
                    let event = log.event as Erc20TransferEvent;
                    let coin = coins?.find((coin) =>
                      coin.tokens.some(
                        (token) =>
                          token.chainId === transaction.chainId &&
                          token.address === log.address,
                      ),
                    );
                    let decimals = coin?.tokens.find(
                      (token) =>
                        token.chainId === transaction.chainId &&
                        token.address === log.address,
                    )?.decimals;
                    // TODO: coins.tokens or chains
                    return (
                      <TableRow key={key}>
                        <td>
                          <a
                            href={`${network?.blockExplorerUrl}/address/${event.from}`}
                            target="_blank"
                            rel="noreferrer"
                            style={{
                              color: "#dadada",
                              fontFamily: "Jetbrains Mono",
                              textDecoration: "none",
                            }}
                          >
                            {formatHash(event.from)}
                          </a>
                        </td>
                        <td>
                          <a
                            href={`${network?.blockExplorerUrl}/address/${event.to}`}
                            target="_blank"
                            rel="noreferrer"
                            style={{
                              color: "#dadada",
                              fontFamily: "Jetbrains Mono",
                              textDecoration: "none",
                            }}
                          >
                            {formatHash(event.to)}
                          </a>
                        </td>
                        <td
                          style={{
                            color: "#dadada",
                            fontFamily: "Inter",
                            fontWeight: 500,
                          }}
                        >
                          {formatUnits(event.amount, decimals).slice(0, 5)}
                        </td>
                        <td
                          style={{
                            color: "#dadada",
                            fontFamily: "Inter",
                            fontWeight: 500,
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            gap: "5px",
                          }}
                        >
                          <img
                            style={{ width: "20px" }}
                            src={coin?.image ?? assets.Disabled}
                            alt="Token"
                          />
                          {coin?.symbol.toUpperCase()}
                        </td>
                      </TableRow>
                    );
                  })}
              </table>
            </Transaction>
          );
        })}
      </TransactionsContainer>
    </>
  );
}

export default Transactions;
