import { styled } from "styled-components";
import { type Network } from "../networks";
import { formatHash } from "../pages/Transactions";

const Container = styled.div`
  position: absolute;
  right: 30px;
  bottom: 30px;
  z-index: 1;
  width: 400px;
  display: flex;
  flex-direction: column-reverse;
  gap: 20px;
`;

const TxContent = styled.div<{ $success: boolean }>`
  display: grid;
  grid-template-columns: 1fr 90px;
  grid-template-rows: 50px 1fr;
  align-items: center;
  padding: 10px;
  border-radius: 10px;
  background-color: #1d1d1d;
  border: 2px solid #fa392d;
  border-color: #404040;
  /* border-color: ${(props) => props.$success && "#6ffa2d"}; */
`;

const NetworkContainer = styled.div`
  grid-row: 1;
  grid-column: 1;
  display: flex;
  align-items: center;
`;

// eslint-disable-next-line @typescript-eslint/no-redeclare
const Network = styled.div`
  display: flex;
  align-items: center;
  height: fit-content;
  width: fit-content;
  padding: 10px;
  gap: 10px;
  border-radius: 10px;

  &:hover {
    background-color: #fff1;
  }
`;

const NetworkLogo = styled.img`
  width: 25px;
  height: 25px;
`;

const NetworkName = styled.p`
  font-family: "Poppins";
  font-size: 17px;
  color: #fff;
`;

const Status = styled.p<{ $success: boolean }>`
  grid-row: 1/-1;
  grid-column: 2;
  font-family: "DM Sans", sans-serif;
  font-size: 20px;
  color: ${(props) => (props.$success ? "#6ffa2d" : "#fa392d")};
`;

const MessageContainer = styled.div`
  grid-row: 2;
  grid-column: 1;
  padding: 10px;
`;

const Hash = styled.p`
  font-family: "DM Sans", sans-serif;
  font-size: 20px;
  color: #fff;
`;

const SmallHash = styled(Hash)`
  font-size: 17px;
  color: #404040;
`;

const Error = styled(SmallHash)`
  color: #fa392d;
`;

export type SettledTxView = {
  network: Network;
  txHash?: string;
  error?: string;
};

export type SettledTxModalProps = {
  tx: Iterable<[string, SettledTxView]>;
};

export default function SettledTxModal({
  tx,
}: SettledTxModalProps): JSX.Element {
  return (
    <Container>
      {Array.from(tx).map(([hash, tx], key) => (
        <a
          key={key}
          href={tx.txHash && `${tx.network.blockExplorerUrl}/tx/${tx.txHash}`}
          rel="noreferrer"
          target="_blank"
          style={{ textDecoration: "none" }}
        >
          <TxContent $success={tx.error === undefined}>
            <NetworkContainer>
              <Network>
                <NetworkLogo src={tx.network.icon} />
                <NetworkName>{tx.network.name}</NetworkName>
              </Network>
            </NetworkContainer>
            <Status $success={tx.error === undefined}>
              {tx.error === undefined ? "Success" : "Failed"}
            </Status>
            <MessageContainer>
              <Hash>{formatHash(hash)}</Hash>
              {tx.txHash && <SmallHash>{formatHash(tx.txHash)}</SmallHash>}
              {tx.error && <Error>{tx.error}</Error>}
            </MessageContainer>
          </TxContent>
        </a>
      ))}
    </Container>
  );
}
