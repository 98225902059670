import { CoreTypes } from "@walletconnect/types";
import { styled } from "styled-components";
import assets from "../assets";
import ga from "react-ga";
import { useEffect } from "react";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: stretch;
  padding: 20px;
  gap: 15px;
  background-color: #1e1e1e;
  border: 2px solid #1e1e1e;
  border-radius: 15px;
  width: 460px;
  min-height: 300px;
  max-height: 600px;
  overflow-y: scroll;
  user-select: none;
`;

const Title = styled.h1`
  font-family: "Poppins";
  font-weight: 400;
  font-size: 23px;
  color: #fff;
`;

const SessionsContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  gap: 5px;
  align-items: center;
`;

const Session = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 45px 220px 1fr 120px;
  grid-template-rows: repeat(2, 1fr);
  column-gap: 10px;
  align-items: center;
  padding: 10px;
  &:hover {
    background-color: #fff1;
  }
`;

const SessionImage = styled.img`
  grid-row: 1/-1;
  grid-column: 1;
  width: 100%;
  height: 100%;
`;

const SessionName = styled.p`
  grid-row: 1;
  grid-column: 2;
  font-family: "DM Sans", sans-serif;
  font-size: 18px;
  font-weight: 500;
  color: #fff;
`;

const SessionUrl = styled(SessionName)`
  grid-row: 2;
  font-weight: 400;
  color: #6f6f6f;
`;

const SessionButton = styled.button`
  grid-row: 1/-1;
  grid-column: 4;
  background: #fa392d;
  font-family: "Inter";
  font-weight: 400;
  font-size: 17px;
  height: 35px;
  border: none;
  outline: none;
  border-radius: 10px;
  cursor: pointer;
  &:hover {
    opacity: 0.9;
  }
`;

export type SessionView = {
  disconnect: () => any;
  peerMeta: CoreTypes.Metadata;
};

export type SessionsModalProps = {
  sessions: SessionView[];
};

function SessionsModal({ sessions }: SessionsModalProps): JSX.Element {
  useEffect(() => {
    ga.modalview("sessions");
    return () =>
      ga.event({
        category: "Connect",
        action: "Closed sessions",
      });
  });
  return (
    <Container>
      <Title>Sessions</Title>
      <SessionsContainer>
        {sessions.map(({ peerMeta, disconnect }, key) => {
          let { value: icon } = peerMeta.icons.values().next();
          return (
            <Session key={key}>
              <SessionImage src={icon ?? assets.Disabled} alt={peerMeta.name} />
              <SessionName>{peerMeta.name}</SessionName>
              <SessionUrl>{peerMeta.url}</SessionUrl>
              <SessionButton
                onClick={() => {
                  ga.event({
                    category: "Connect",
                    action: "Disconnected session",
                    label: peerMeta.name,
                  });
                  disconnect();
                }}
              >
                Disconnect
              </SessionButton>
            </Session>
          );
        })}
      </SessionsContainer>
    </Container>
  );
}

export default SessionsModal;
