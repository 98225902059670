import BlockiesSvg from "blockies-react-svg";
import { styled } from "styled-components";
import { useToggle } from "usehooks-ts";
import assets from "../assets";
import type { Network } from "../networks";

const Container = styled.div`
  width: 100%;
  --border: 2px solid #404040;
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  border: var(--border);
  border-radius: 15px;
  user-select: text;
  & *::-moz-selection {
    background-color: #404040;
  }
  & *::selection {
    background-color: #404040;
  }
`;

const NetworkContainer = styled.div`
  border-bottom: var(--border);
  padding: 10px;
`;

// eslint-disable-next-line @typescript-eslint/no-redeclare
const Network = styled.div`
  width: fit-content;
  display: flex;
  align-items: center;
  padding: 5px;
  gap: 5px;
  border-radius: 10px;
  &:hover {
    background-color: #fff1;
  }
`;

const NetworkLogo = styled.img`
  width: 30px;
  height: auto;
`;

const NetworkName = styled.p`
  font-family: "Poppins";
  font-size: 17px;
  color: #fff;
`;

const ShortDescriptionContainer = styled.div`
  padding: 10px;
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  border-bottom: var(--border);
  gap: 10px;
`;

const FunctionName = styled.p`
  font-family: "DM Sans", sans-serif;
  font-weight: 500;
  font-size: 17px;
  color: #fff;
`;

const AddressContainer = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  grid-template-rows: repeat(2, 1fr);
  column-gap: 10px;
`;

const Avatar = styled(BlockiesSvg)`
  grid-column: 1;
  grid-row: 1/-1;
  width: 50px;
  height: auto;
  border-radius: 50%;
`;

const AddressAlias = styled.p`
  grid-row: 1;
  grid-column: 2;
  font-family: "DM Sans", sans-serif;
  font-weight: 400;
  font-size: 17px;
  color: #6f6f6f;
`;

const Address = styled(AddressAlias)`
  grid-row: 2;
  color: #fff;
`;

const ExpandContainer = styled.button<{ $expanded: boolean }>`
  padding: 10px;
  background: none;
  outline: none;
  border: none;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  border-bottom: ${(props) => props.$expanded && "var(--border)"};
`;

const LongDescriptionContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  padding: 10px;
  gap: 5px;
`;

const DescriptionText = styled(Address)`
  max-width: 450px;
  word-wrap: break-word;
`;

const ExpandArrow = styled.img<{ $expanded: boolean }>`
  transform: ${(props) => props.$expanded && "rotate(180deg)"};
  transition: transform 100ms ease-in-out;
`;

export type TransactionDataProps = {
  network: Network;
  functionName: string;
  address: string;
  addressAlias: string;
  details: Record<string, string>;
};

function TransactionData({
  network,
  functionName,
  address,
  addressAlias,
  details,
}: TransactionDataProps): JSX.Element {
  const [expanded, toggleExpanded] = useToggle(false);
  return (
    <Container>
      <NetworkContainer>
        <Network>
          <NetworkLogo src={network.icon} alt="" />
          <NetworkName>{network.name}</NetworkName>
        </Network>
      </NetworkContainer>
      <ShortDescriptionContainer>
        <FunctionName>{functionName}</FunctionName>
        <AddressContainer>
          <Avatar address={address} width="50" />
          <AddressAlias>{addressAlias}</AddressAlias>
          <Address>{address}</Address>
        </AddressContainer>
      </ShortDescriptionContainer>
      <ExpandContainer onClick={toggleExpanded} $expanded={expanded}>
        <DescriptionText>Transaction details</DescriptionText>
        <ExpandArrow
          src={assets.buttons.ArrowDown}
          alt="arrow-down"
          $expanded={expanded}
        />
      </ExpandContainer>
      {expanded && (
        <LongDescriptionContainer>
          {Object.entries(details).map(([key, value]) => (
            <DescriptionText key={key}>
              {key}: {value}
            </DescriptionText>
          ))}
        </LongDescriptionContainer>
      )}
    </Container>
  );
}

export default TransactionData;
