import {
  ApiClient,
  Notification,
  RelayClient,
  apiV2,
  apiV3,
  transaction,
} from "@uroboros-labs/wallet-sdk";
import { EventEmitter } from "events";
import TypedEmitter from "typed-emitter";

export type Events = {
  transaction: (notificaiton: Notification, subscription: number) => void;
};

export const RELAY_SUBSCRIPTIONS = new EventEmitter() as TypedEmitter<Events>;

export const API_CLIENT = new ApiClient("https://api.uroboros.co/wallet-api");

export const TRANSACTION_CLIENT = new transaction.TransactionApi();

export const API_V2_CLIENT = new apiV2.ApiV2Client(
  "https://api.uroboros.co/wallet/v2",
);

export const API_V3_CLIENT = new apiV3.ApiV3Client();

export const RELAY_CLIENT = new RelayClient(
  "wss://api.uroboros.co/relay",
  undefined,
  () => {
    RELAY_CLIENT.subscribeTransaction((notification, subsctiption) => {
      console.log(notification, subsctiption);
      RELAY_SUBSCRIPTIONS.emit("transaction", notification, subsctiption);
    });
  },
);

RELAY_CLIENT.subscribeTransaction((notification, subsctiption) => {
  console.log(notification, subsctiption);
  RELAY_SUBSCRIPTIONS.emit("transaction", notification, subsctiption);
});
