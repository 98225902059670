import { JsonRpcError, apiV2 } from "@uroboros-labs/wallet-sdk";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { z } from "zod";
import { API_V3_CLIENT } from "../api";

export default function useAccount() {
  const [account, setAccount] = useState<apiV2.Account>();
  const [assets, setAssets] = useState<apiV2.AssetOrToken[]>();
  const [transactions, setTransactions] = useState<apiV2.Transaction[]>();
  const [error, setError] = useState<z.ZodError | JsonRpcError>();
  const { owner, nonce } = useParams();
  useEffect(() => {
    if (
      !owner ||
      !nonce ||
      !/0x[0-9a-fA-F]{40}/.test(owner) ||
      !/0x[0-9a-fA-F]{1,64}/.test(nonce)
    ) {
      return;
    }

    let controller = new AbortController();
    API_V3_CLIENT.getAccount({ owner, nonce }, { signal: controller.signal })
      .then((account) => {
        setAccount({
          address: account.address,
          value: account.value,
          valueChange24h: account.valueChange24h,
          valueChangePercentage24h: account.valueChangePercentage24h,
          created: [],
        });
        setAssets(account.assets);
      })
      .catch((error: unknown) => {
        console.log(error);
        if (error instanceof JsonRpcError) {
          console.error({ api_request_account: error });
          setError(error);
        }
      });

    API_V3_CLIENT.getAccountTransactions(
      { owner, nonce },
      { signal: controller.signal },
    )
      .then(setTransactions)
      .catch((error: unknown) => {
        console.log(error);
        if (error instanceof JsonRpcError) {
          console.error({ api_request_account: error });
          setError(error);
        }
      });
  }, [owner, nonce]);
  return { account, assets, transactions, owner, nonce, error };
}
