import BlockiesSvg from "blockies-react-svg";
import { useEffect, useMemo, useState } from "react";
import { styled } from "styled-components";
import assets from "../assets";
import { NetworkLike } from "./SelectNetwork";

const OuterContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  border: 2px solid #404040;
  border-radius: 10px;
`;

const NetworkContainer = styled.div`
  border-bottom: 2px solid #404040;
  padding: 10px;
`;

const BaseButton = styled.button`
  background: none;
  outline: none;
  border: none;
  padding: 5px;
  border-radius: 10px;
  cursor: pointer;
  &:hover {
    background-color: #fff1;
  }
`;

const NetworkButton = styled(BaseButton)`
  display: flex;
  align-items: center;
  gap: 10px;
`;

const NetworkLogo = styled.img`
  width: 25px;
  height: auto;
`;

const NetworkName = styled.p`
  font-family: "Inter";
  font-weight: 500;
  font-size: 17px;
  color: #fff;
`;

const ArrowDown = styled.img``;

const AddressContainer = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  grid-template-rows: repeat(2, 1fr);
  column-gap: 15px;
  padding: 10px;
`;

const Avatar = styled(BlockiesSvg)`
  grid-column: 1;
  grid-row: 1/-1;
  border-radius: 50%;
  width: 50px;
  height: auto;
  background-color: #6f6f6f;
`;

const AddressAlias = styled.p`
  grid-column: 2;
  grid-row: 1;
  font-family: "DM Sans", sans-serif;
  font-weight: 500;
  font-size: 17px;
  color: #6f6f6f;
`;

const Address = styled(AddressAlias)`
  color: #fff;
  grid-row: 2;
`;

const AddressInput = styled.input`
  grid-column: 2;
  grid-row: 1/-1;
  background: none;
  border: none;
  outline: none;
  font-family: "DM Sans", sans-serif;
  font-weight: 500;
  font-size: 17px;
  color: #fff;
`;

const ErrorContainer = styled.div`
  width: 95%;
  padding: 5px;
  background-color: #823a3a;
`;

const Error = styled.p`
  font-family: "DM Sans", sans-serif;
  font-size: 17px;
  color: #ff4141;
`;

function testAddress(address: string): boolean {
  return /^0x[A-Fa-f0-9]{40}$/.test(address);
}

export type NetworkAddressSelectProps = {
  network?: NetworkLike;
  defaultAddress?: string;
  onNetworkClick?: () => void;
  onAddressChange?: (address: string) => void;
};

function NetworkAddressSelect({
  network,
  defaultAddress,
  onNetworkClick,
  onAddressChange,
}: NetworkAddressSelectProps): JSX.Element {
  const [active, setActive] = useState(true);
  const [address, setAddress] = useState(defaultAddress ?? "");
  const isValidAddress = useMemo(
    () => address === "" || testAddress(address),
    [address],
  );
  useEffect(() => {
    onAddressChange?.(address);
  }, [address, onAddressChange]);
  return (
    <OuterContainer>
      <Container>
        <NetworkContainer>
          <NetworkButton onClick={onNetworkClick}>
            {network && <NetworkLogo src={network.icon} alt={network.name} />}
            <NetworkName>{network?.name ?? "Select"}</NetworkName>
            <ArrowDown src={assets.buttons.ArrowDown} alt="arrow-down" />
          </NetworkButton>
        </NetworkContainer>
        <AddressContainer onClick={() => setActive(true)}>
          <Avatar address={address} width="50" />
          {active ? (
            <AddressInput
              placeholder="0x"
              value={address}
              onBlur={() => setActive(false)}
              onKeyDown={(e) => {
                if (e.key === "Enter") setActive(false);
              }}
              onChange={(e) => setAddress(e.target.value)}
            />
          ) : (
            <>
              <AddressAlias>Unknown</AddressAlias>
              <Address>{address}</Address>
            </>
          )}
        </AddressContainer>
      </Container>
      {!isValidAddress && (
        <>
          <ErrorContainer>
            <Error>Error! Invalid address format.</Error>
          </ErrorContainer>
        </>
      )}
    </OuterContainer>
  );
}

export default NetworkAddressSelect;
