import { styled } from "styled-components";
import { Main, Title } from "./Home";
import { useWeb3React } from "@web3-react/core";
import { useCallback, useEffect } from "react";
import { unlock } from "../unlocker";
import { useNavigate } from "react-router-dom";

const Input = styled.input`
  margin-top: 25px;
  background-color: #afafaf;
  outline: none;
  border: none;
  border-radius: 10px;
  width: 500px;
  padding: 15px;
  color: #404040;
  font-size: 25px;
`;

export default function Code(): JSX.Element {
  const { provider, connector } = useWeb3React();
  const navigate = useNavigate();

  useEffect(() => {
    connector.connectEagerly?.();
  }, [connector]);

  const onCodeChange = useCallback(
    async (code: string) => {
      if (code === "") return;
      let signer = provider?.getSigner();
      if (signer === undefined) return;
      let signature = await signer.signMessage(code);
      await unlock(code, signature);
      navigate(-1);
    },
    [provider, navigate],
  );

  return (
    <Main>
      <Title>Access Code</Title>
      <Input
        placeholder="Paste code..."
        onChange={(e) => onCodeChange(e.target.value)}
      />
    </Main>
  );
}
