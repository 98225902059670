import { ReactNode } from "react";
import { useWindowSize } from "usehooks-ts";
import { Main } from "../pages/Home";
import styled from "styled-components";

const Container = styled(Main)`
  padding: 20px;
`;

const Title = styled.h1`
  font-family: "Poppins";
  font-size: 30px;
  color: #fff;
  text-align: center;
  word-wrap: break-word;
`;

export type MobileDisclaimerProps = {
  children: ReactNode;
};

export default function MobileDisclaimer({
  children,
}: MobileDisclaimerProps): JSX.Element {
  const { height, width } = useWindowSize();
  if (height < width) {
    return <>{children}</>;
  } else {
    return (
      <Container>
        <Title>Not available on mobile</Title>
      </Container>
    );
  }
}
