import { styled } from "styled-components";
import TransactionData, { TransactionDataProps } from "./TransactionData";
import GasSelect from "./GasSelect";
import { useState, useCallback, useMemo, useEffect } from "react";
import {
  signUrbTxPayload,
  type Asset,
  UrbTxMessageResponse,
  JsonRpcError,
} from "@uroboros-labs/wallet-sdk";
import SelectAsset from "./SelectAsset";
import { API_CLIENT, RELAY_CLIENT } from "../api";
import { useWeb3React } from "@web3-react/core";
import { Button } from "./SendModal";
import { ErrorText } from "./SwapModal";
import { formatUnits } from "@ethersproject/units";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 25px;
  gap: 20px;
  border-radius: 30px;
  min-height: 300px;
  max-height: 700px;
  overflow-y: scroll;
  min-width: 450px;
  background-color: #151515;
  border: 2px solid #404040;
  user-select: none;
`;

const Title = styled.h1`
  font-family: "Poppins";
  font-weight: 500;
  font-size: 23px;
  color: #fff;
  align-self: center;
`;

export type TransactionModalProps = TransactionDataProps & {
  account?: string;
  assets?: Asset[];
  resolve?: (hash: string) => void;
};

function TransactionModal(props: TransactionModalProps): JSX.Element {
  const { assets, account, network, address, details, resolve } = props;
  const gasAssets = useMemo(
    () =>
      assets?.filter(
        (asset) =>
          (asset.isGas || asset.isSwap) &&
          asset.coins.findIndex((coin) => coin.chainId.eq(network.chainId)) !==
            -1,
      ),
    [assets, network],
  );
  const [select, setSelect] = useState(false);
  const [gasAsset, setGasAsset] = useState<Asset | undefined>(
    () => assets?.find((asset) => asset.isGas),
  );
  const { connector, provider } = useWeb3React();
  const [pending, setPending] = useState(false);

  const [response, setResponse] = useState<UrbTxMessageResponse>();
  const [error, setError] = useState<string>();

  const gasAmount = useMemo(() => {
    if (response !== undefined) {
      let format = formatUnits(response.amountFeeGas, response.gasDecimals);
      return Number.parseFloat(format).toFixed(5);
    }
  }, [response]);

  useEffect(() => {
    let controller = new AbortController();
    console.log({ gasAsset, account });
    if (!gasAsset || !account) return;
    const token = gasAsset.coins.find((coin) =>
      coin.chainId.eq(network.chainId),
    )?.address;
    if (!token) {
      return;
    }
    console.log({ token });
    API_CLIENT.getMessage({
      address: account,
      chainId: network.chainId,
      request: {
        to: address,
        value: details.value,
        data: details.data,
        gas: details.gas,
        op: "call",
        relayersRoot:
          "0x77cb59930f35bb568706cf600397f48bcf55d15e389f204b19a7d79cb96d6a36",
        token,
      },
    })
      .then(setResponse)
      .catch((error) => {
        if (error instanceof JsonRpcError) setError(error.message);
        else if (typeof error === "string") setError(error);
        else console.log({ error });
      });
    return () => controller.abort();
  }, [account, address, details, gasAsset, network]);

  const send = useCallback(async () => {
    const signer = provider?.getSigner();
    console.log({ account, assets, gasAsset, signer });
    if (!response || !account || !signer) {
      return;
    }
    await connector.activate(network.addEthereumChainParam);
    let signature = await signUrbTxPayload(
      network.chainId,
      account,
      response.message,
      response.nonce,
      signer,
    );
    return RELAY_CLIENT.processTransaction({
      type: "urb-transaction",
      message: response.message,
      messageNonce: response.nonce,
      signature,
      wallet: account,
      creationNonce: "0x0",
      chainId: network.chainId,
    });
  }, [
    provider,
    account,
    assets,
    gasAsset,
    response,
    connector,
    network.addEthereumChainParam,
    network.chainId,
  ]);

  return (
    <Container>
      {select ? (
        <SelectAsset
          assets={gasAssets ?? []}
          onSelect={(asset) => {
            // @ts-ignore
            setGasAsset(asset);
            setSelect(false);
          }}
          isWarning={(asset) => (asset as Asset).isGas === true}
        />
      ) : (
        <>
          <Title>Approve transaction</Title>
          <TransactionData {...props} />
          <GasSelect
            amount={gasAmount}
            selected={gasAsset}
            onSelectClick={() => setSelect(true)}
          />
          {error && <ErrorText>{error}</ErrorText>}
          <Button
            $pending={pending}
            onClick={async () => {
              setPending(true);
              try {
                let hash = await send();
                if (hash !== undefined) {
                  resolve?.(hash);
                }
              } finally {
                setPending(false);
              }
            }}
          >
            Send
          </Button>
        </>
      )}
    </Container>
  );
}

export default TransactionModal;
