import { apiV2 } from "@uroboros-labs/wallet-sdk";
import { Web3ReactProvider, useWeb3React } from "@web3-react/core";
import { useEffect, useState } from "react";
import ga from "react-ga";
import {
  BrowserRouter,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from "react-router-dom";
import { API_V2_CLIENT } from "./api";
import Dashboard from "./components/Dashboard";
import MobileDisclaimer from "./components/MobileDisclaimer";
import { CONNECTORS } from "./connections";
import { ApiContext } from "./context";
import Code from "./pages/Code";
import Creation from "./pages/Creation";
import Home from "./pages/Home";
import Interface from "./pages/Interface";
import Transactions from "./pages/Transactions";

function App(): JSX.Element {
  const navigate = useNavigate();
  const { account: owner } = useWeb3React();
  const [coins, setCoins] = useState<apiV2.Coin[]>();
  const [connect, setConnect] = useState(false);
  const location = useLocation();

  useEffect(() => {
    ga.pageview(location.pathname);
  }, [location]);

  useEffect(() => {
    if (connect && owner !== undefined) {
      navigate(`/${owner}/0x0/dashboard`);
      setConnect(false);
    }
  }, [connect, navigate, owner]);

  useEffect(() => {
    let controller = new AbortController();
    API_V2_CLIENT.getCoins({ signal: controller.signal })
      .then(setCoins)
      .catch((e) => {
        console.log(e, e.constructor);
      });
    return () => controller.abort();
  }, []);

  return (
    <ApiContext.Provider value={{ coins, setCoins }}>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/code" element={<Code />} />
        <Route
          path="/creation"
          element={
            <Creation
              connectors={CONNECTORS}
              setConnect={() => setConnect(true)}
            />
          }
        />
        <Route path="/:owner/:nonce" element={<Interface />}>
          <Route path="dashboard" element={<Dashboard />} />
          <Route path="transactions" element={<Transactions />} />
        </Route>
      </Routes>
    </ApiContext.Provider>
  );
}

function ContextProvider(): JSX.Element {
  return (
    <Web3ReactProvider connectors={CONNECTORS}>
      <BrowserRouter>
        <MobileDisclaimer>
          <App />
        </MobileDisclaimer>
      </BrowserRouter>
    </Web3ReactProvider>
  );
}

export default ContextProvider;
